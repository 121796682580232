import type { Activity, Chapter, Course, Module } from "~/types";

export const getNextActivity = (
  course?: Course,
  currentChapterId?: string,
  currentModuleId?: string,
  currentActivityId?: string,
  currentActivityType?: string,
) => {
  if (!course) return null;
  const currentChapterIndex = course.chapters.findIndex(
    (ch: Chapter) => ch.id == currentChapterId,
  );
  const currentChapter = course.chapters[currentChapterIndex];

  if (!currentChapter) return null;

  const currentModuleIndex = currentChapter.modules.findIndex(
    (mod: Module) => mod.id == currentModuleId,
  );
  const currentModule = currentChapter.modules[currentModuleIndex];

  if (!currentModule) return null;

  const currentActivityIndex = currentModule.activities.findIndex(
    (act: Activity) =>
      act.type === currentActivityType && act.id == currentActivityId,
  );

  // Case 1: There's a next activity in the same module
  if (currentActivityIndex < currentModule.activities.length - 1) {
    const nextActivity = currentModule.activities[currentActivityIndex + 1];
    return {
      chapterId: currentChapter.id,
      moduleId: currentModule.id,
      activityId: nextActivity.id,
      activityType: nextActivity.type,
    };
  }

  // Case 2: No more activities in this module, move to next module
  if (currentModuleIndex < currentChapter.modules.length - 1) {
    const nextModule = currentChapter.modules[currentModuleIndex + 1];
    return {
      chapterId: currentChapter.id,
      moduleId: nextModule.id,
      activityId: nextModule.activities[0].id,
      activityType: nextModule.activities[0].type,
    };
  }

  // Case 3: No more modules in this chapter, move to the next chapter's first module/activity
  if (currentChapterIndex < course.chapters.length - 1) {
    const nextChapter = course.chapters[currentChapterIndex + 1];
    const nextModule = nextChapter.modules[0];
    return {
      chapterId: nextChapter.id,
      moduleId: nextModule.id,
      activityId: nextModule.activities[0].id,
      activityType: nextModule.activities[0].type,
    };
  }

  // Case 4: No more chapters, course is finished
  return {
    chapterId: null,
    moduleId: null,
    activityId: null,
    activityType: null,
  };
};
