export const VasodilateIcon = () => (
  <svg
    width='15'
    height='16'
    viewBox='0 0 15 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.4987 14.6666C11.1806 14.6666 14.1654 11.6819 14.1654 7.99998C14.1654 4.31808 11.1806 1.33331 7.4987 1.33331C3.8168 1.33331 0.832031 4.31808 0.832031 7.99998C0.832031 11.6819 3.8168 14.6666 7.4987 14.6666Z'
      stroke='#3B8BF6'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.4987 12.6666C10.076 12.6666 12.1654 10.5773 12.1654 7.99998C12.1654 5.42265 10.076 3.33331 7.4987 3.33331C4.92137 3.33331 2.83203 5.42265 2.83203 7.99998C2.83203 10.5773 4.92137 12.6666 7.4987 12.6666Z'
      stroke='#3B8BF6'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
