import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getCourse } from "~/api/courses/course";

export const useGetCourseCached = (id?: string) => {
  const queryClient = useQueryClient();

  const cachedCourseQuery = useQuery({
    queryKey: ["getCourseCached", id],
    queryFn: () =>
      queryClient.ensureQueryData({
        queryKey: ["getCourse", id],
        queryFn: () => getCourse(id ?? ""),
      }),
  });

  return { cachedCourseQuery };
};
