import { RoundedCheckIcon } from "../../icons";

export interface CorrectAnswerResultTextProps {
  text: string;
}

export const CorrectAnswerResultText = ({
  text,
}: CorrectAnswerResultTextProps) => {
  return (
    <div className='flex flex-row items-center gap-2 rounded-xl border border-success-800 bg-success-30 p-4 text-success-800'>
      <RoundedCheckIcon className='min-w-fit' />
      <span className='font-medium'>{text}</span>
    </div>
  );
};
