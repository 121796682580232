import type { SVGProps } from "~/constants";

export const SliderIcon = ({ className, ...props }: SVGProps) => (
  <svg
    className={className}
    {...props}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.85187 6.22223L2.0741 8.00001L3.85187 9.77778'
      stroke='#27AED7'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.1482 6.22223L13.926 8.00001L12.1482 9.77778'
      stroke='#27AED7'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.0741 8H13.9259'
      stroke='#27AED7'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
