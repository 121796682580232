import { tw } from "~/shared/utils";
import type { Chapter } from "~/types";
import { ModuleCard } from "../modules/ModuleCard";
import { ChapterCardHeader } from "./ChapterCardHeader";

interface ModuleCardProps {
  chapter: Chapter;
  isDrawer?: boolean;
}

export const ChapterCard = ({ chapter, isDrawer = false }: ModuleCardProps) => {
  return (
    <div
      className={tw(
        "flex flex-col gap-4 rounded-3xl border border-primary-50 bg-white p-8",
        isDrawer && "border-none py-0",
      )}
    >
      <ChapterCardHeader chapter={chapter} isDrawer />
      {chapter.modules.map((module) => (
        <ModuleCard key={module.id} module={module} chapterId={chapter.id} />
      ))}
    </div>
  );
};
