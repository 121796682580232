import { api } from "./axios";

export interface SlotType {
  id: number;
  code: string;
  correctOptions: number[];
}
export interface DragAndDropActivityResponse {
  data: {
    id: number;
    title: string;
    description: string;
    type: "drag" | "slider" | "multiple" | "text" | "video";
    explanation: string;
    options: {
      id: number;
      value: string;
    }[];
    slots: SlotType[];
  };
}

export const getDragActivity = async (activityId: string) => {
  const { data } = await api.get<DragAndDropActivityResponse>(
    `/activities/drag-and-drop/${activityId}`,
  );
  return data;
};
