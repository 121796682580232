import { useMutation } from "@tanstack/react-query";
import { t } from "i18next";
import toast from "react-hot-toast";

import type { ForgotPasswordRequest } from "~/api/auth";
import { forgotPassword } from "~/api/auth";

interface UseForgotPasswordProps {
  onSuccess: (payload: ForgotPasswordRequest) => void;
}
export const useForgotPasswordMutation = ({
  onSuccess,
}: UseForgotPasswordProps) => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: (_, payload) => {
      onSuccess(payload);
    },
    onError: () => {
      toast.error(t("ui.errorMessage"));
    },
  });

  return { mutate, isPending, isError, error };
};
