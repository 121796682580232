import { useQuery } from "@tanstack/react-query";

import { getDragActivity } from "~/api/getDragActivity";
import { ACTIVITY_TYPES } from "~/types";

export const useGetDragActivityQuery = (activityId: string, type?: string) => {
  const { data, isPending } = useQuery({
    queryKey: ["activity", activityId],
    queryFn: () => getDragActivity(activityId),
    enabled: !!activityId && type === ACTIVITY_TYPES.dragAndDrop,
  });
  return { data: data?.data, isPending };
};
