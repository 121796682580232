import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { SliderActivity } from "~/api/getSliderActivity";
import { ActivityLayout } from "~/layout/ActivityLayout";
import { getRandomErrorMessage } from "~/shared/utils/activities/getRandomErrorMessage";
import { SliderContent } from "./SliderContent";

interface SliderTwoProps {
  activityData: SliderActivity;
}

export const SliderOne = ({ activityData }: SliderTwoProps) => {
  const [waterHeight, setWaterHeight] = useState(0);
  const [hasSuccessMessage, setHasSuccessMessage] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const firstSlider = activityData?.sliders[0];
    if (firstSlider?.initial) setWaterHeight(Number(firstSlider.initial));
  }, [activityData]);

  const { t } = useTranslation();

  if (!activityData) return <p>No activity data.</p>;

  const { sliders, title, description, explanation } = activityData;

  const [waterSlider] = sliders;

  const waterSliderMinAnswer = Number(waterSlider.answer_minimum);
  const waterSliderMaxAnswer = Number(waterSlider.answer_maximum);

  const validateAnswer = () => {
    const isCorrect =
      waterHeight >= waterSliderMinAnswer &&
      waterHeight <= waterSliderMaxAnswer;
    setHasSuccessMessage(isCorrect);
    setHasErrorMessage(!isCorrect);
    if (!isCorrect) {
      setErrorMessage(getRandomErrorMessage());
    }
  };

  const resetActivityState = () => {
    setWaterHeight(Number(waterSlider?.initial ?? 0));
    setHasErrorMessage(false);
    setHasSuccessMessage(false);
  };

  return (
    <ActivityLayout
      title={t(`activities.${title}`)}
      successMessage={hasSuccessMessage ? t(`activities.${explanation}`) : ""}
      hasError={hasErrorMessage}
      errorMessage={errorMessage}
      onCheckClick={validateAnswer}
      isCheckDisabled={false}
      hasAbsolutePositionExplanation={true}
      resetActivityState={resetActivityState}
    >
      <SliderContent
        description={description}
        waterSlider={waterSlider}
        waterHeight={waterHeight}
        setWaterHeight={setWaterHeight}
      />
    </ActivityLayout>
  );
};
