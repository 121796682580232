import React from "react";

import { CloseButton, Content, Overlay, Root, Title } from "../../ui";

export const ImageModal = ({
  isOpen,
  onClose,
  title,
  imageSVG,
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  imageSVG: JSX.Element;
}) => {
  return (
    <Root open={isOpen} onOpenChange={onClose}>
      <Overlay />
      <Content className='flex w-1/3 flex-col items-center gap-10 p-5'>
        <div className='flex w-full items-center justify-between border-b border-gray-200 pb-2'>
          <Title className='font-bold'>{title}</Title>
          <CloseButton />
        </div>
        {imageSVG}
      </Content>
    </Root>
  );
};
