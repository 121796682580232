import type { PropsWithChildren } from "react";
import React from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RadioGroup,
} from "~/shared";

interface BaseRadioButtonProps<TFormValues extends FieldValues>
  extends PropsWithChildren {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
}

export const BaseRadioButton = <TFormValues extends FieldValues>({
  name,
  control,
  children,
}: BaseRadioButtonProps<TFormValues>) => {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => (
        <FormItem className='space-y-3'>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className='flex flex-col space-y-1'
            >
              {children}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
