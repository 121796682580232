import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ROUTES } from "~/router";
import { ChevronIcon, Text } from "~/shared";
import { ForgotPasswordForm } from "~/shared/components/authorization/ForgotPasswordForm";
import { ForgotPasswordSent } from "~/shared/components/authorization/ForgotPasswordSent";
import { Heading } from "~/shared/components/ui/Heading/Heading";

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();

  const title = email
    ? t("forgotPassword.linkSent")
    : t("forgotPassword.forgotPassword");
  const description = email
    ? t("forgotPassword.linkSentDescription")
    : t("forgotPassword.description");

  return (
    <div className='flex w-2/3 flex-col gap-12'>
      <div className='flex flex-col items-center justify-center gap-12'>
        <Link
          to={ROUTES.login}
          className='relative flex w-full flex-row items-center justify-center'
        >
          <ChevronIcon
            direction='left'
            className='absolute left-0 text-gray-600'
          />
          <Text className='text-gray-600'>
            {t("forgotPassword.backToLogIn")}
          </Text>
        </Link>
        <Heading className='font-tobias'>{title}</Heading>
        <Text className='text-center'>{description}</Text>
        {!email ? (
          <ForgotPasswordForm setEmail={setEmail} />
        ) : (
          <ForgotPasswordSent email={email} />
        )}
      </div>
    </div>
  );
};
