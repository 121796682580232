import { getRandomErrorMessage } from "~/shared/utils/activities/getRandomErrorMessage";
import { RoundedXIcon } from "../../icons";

export const IncorrectAnswerResultText = ({
  message,
}: {
  message?: string;
}) => {
  const randomErrorMessage = message ?? getRandomErrorMessage();
  return (
    <div className='flex flex-row items-center gap-2 rounded-xl border border-error-800 bg-error-50 p-4 text-error-800'>
      <RoundedXIcon />
      <span className='font-medium'>{randomErrorMessage}</span>
    </div>
  );
};
