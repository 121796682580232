import type { SVGProps } from "~/constants";

export const DragAndDropIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M3.852 6.22223L2.07422 8.00001L3.852 9.77778'
      stroke='#955EED'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.22217 3.85181L7.99995 2.07404L9.77772 3.85181'
      stroke='#955EED'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.77772 12.1483L7.99995 13.926L6.22217 12.1483'
      stroke='#955EED'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.1482 6.22223L13.926 8.00001L12.1482 9.77778'
      stroke='#955EED'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.07422 8H13.9261'
      stroke='#955EED'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 2.07404V13.9259'
      stroke='#955EED'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
