import React from "react";
import { useTranslation } from "react-i18next";

import type { SliderType } from "~/api/getSliderActivity";
import { ClipboardListIcon } from "~/shared";
import BlondePersonIcon from "~/shared/components/icons/BlondePersonIcon";
import { Text } from "~/shared/components/ui";
import {
  COMMON_SLIDER_STYLES,
  INPUT_OFFSET_LEFT,
  SINGLE_SLIDER_MULTIPLIER,
  SLIDER_BASE_HEIGHT,
  SliderControl,
  WATER_FILL_COLOR,
} from "..";
import { SliderInfo } from "./SliderInfo";

interface SliderContentProps {
  description: string;
  waterSlider: SliderType;
  waterHeight: number;
  setWaterHeight: (value: number) => void;
}

export const SliderContent: React.FC<SliderContentProps> = ({
  description,
  waterSlider,
  waterHeight,
  setWaterHeight,
}) => {
  const { t } = useTranslation();
  const inputStyle: React.CSSProperties = {
    left: INPUT_OFFSET_LEFT,
  };
  return (
    <div className='relative flex h-full w-full items-center justify-center'>
      <div className='whitespace-no-wrap absolute left-0 flex max-w-[340px] flex-col gap-1 overflow-hidden rounded-lg bg-primary-50 p-4'>
        <ClipboardListIcon className='h-5 w-5' />
        <Text className='leading-6'>{t(`activities.${description}`)}</Text>
      </div>
      <div className='relative flex w-9/12 justify-center'>
        <div className='relative flex w-9/12 justify-center'>
          <div className='relative' style={COMMON_SLIDER_STYLES}>
            <SliderInfo />
            <BlondePersonIcon
              className='h-full w-full'
              sliderHeight={
                SLIDER_BASE_HEIGHT + waterHeight * SINGLE_SLIDER_MULTIPLIER
              }
              fillColor={WATER_FILL_COLOR}
            />
            <SliderControl
              value={waterHeight}
              onChange={(e) => setWaterHeight(Number(e.target.value))}
              min={Number(waterSlider.minimum)}
              max={Number(waterSlider.maximum)}
              style={inputStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
