import React from "react";

import Skeleton from "../ui/Skeleton/Skeleton";

const CourseCardSkeleton = () => {
  return (
    <div className='h-fit w-course-card rounded-xl p-4 shadow-lg'>
      <Skeleton className='h-32 w-full rounded-lg' />

      <div className='mt-4 space-y-2'>
        <Skeleton className='h-4 w-1/4' />
        <Skeleton className='h-6 w-3/4' />

        <Skeleton className='h-3 w-full' />
        <Skeleton className='h-3 w-5/6' />
        <Skeleton className='h-3 w-4/6' />
      </div>

      <div className='mt-4'>
        <Skeleton className='h-4 w-1/6' />
      </div>
    </div>
  );
};

export default CourseCardSkeleton;
