import { useTranslation } from "react-i18next";

import { ChapterCard } from "~/shared/components/courses/chapters/ChapterCard";
import { Text } from "~/shared/components/ui";
import { Drawer } from "~/shared/components/ui/Drawer/Drawer";
import { useUiStore } from "~/stores";
import type { Course } from "~/types/courses";

interface CourseDrawerProps {
  course: Course;
}

export const CourseDrawer = ({ course }: CourseDrawerProps) => {
  const { chapters } = course;
  const { t } = useTranslation();
  const { isCourseDrawerOpen, setIsCourseDrawerOpen } = useUiStore();

  return (
    <Drawer
      isOpen={isCourseDrawerOpen}
      onClose={() => setIsCourseDrawerOpen(false)}
    >
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col gap-6'>
          <Text className='font-tobias text-2xl'>
            {t("courseDrawer.title")}
          </Text>
          <Text className='text-gray-600'>{t("courseDrawer.description")}</Text>
        </div>
        <div className='flex flex-col gap-14'>
          {chapters.map((chapter) => (
            <ChapterCard key={chapter.id} chapter={chapter} isDrawer />
          ))}
        </div>
      </div>
    </Drawer>
  );
};
