import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import { ROUTES } from "~/router";
import { ChevronIcon } from "~/shared/components/icons";
import { tw } from "~/shared/utils";
import type { ActivityType } from "~/shared/utils/activities/getActivityIcon";
import { getActivityIcon } from "~/shared/utils/activities/getActivityIcon";
import { ACTIVITY_TYPES } from "~/types";

interface PathStepItemProps {
  pathCode: string;
  activityLabel: string;
  isLast?: boolean;
}
function isActivityType(value?: string): value is ActivityType {
  if (!value) {
    return false;
  }
  const activityTypeArray = Object.values(ACTIVITY_TYPES);
  return activityTypeArray.includes(value);
}
export const PathStepItem = ({
  pathCode,
  isLast,
  activityLabel,
}: PathStepItemProps) => {
  const { t } = useTranslation();
  let activityType;
  if (isLast) {
    activityType = pathCode.split(".").pop();
  }
  const { courseId } = useParams();
  if (!courseId) return null;

  return (
    <div className='flex flex-row items-center gap-2 text-gray-600'>
      {isLast && isActivityType(activityType) ? (
        <div className='flex flex-row gap-2'>
          {getActivityIcon({ activityType, size: "sm" })}
          <p className={tw(isLast ? "text-neutral-black" : "underline")}>
            {t(activityLabel)}
          </p>
        </div>
      ) : (
        <div className='flex flex-row items-center gap-2'>
          <Link
            to={generatePath(ROUTES.courseDetails, { courseId })}
            className={tw(isLast ? "text-neutral-black" : "underline")}
          >
            {t(pathCode)}
          </Link>
          <ChevronIcon className='size-2' />
        </div>
      )}
    </div>
  );
};
