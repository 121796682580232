import React from "react";
import { useTranslation } from "react-i18next";

import { InfoIcon, Text, WavingHandIcon } from "~/shared";
import { AboutUsSection } from "~/shared/components/home/AboutUsSection";
import { CourseCard } from "~/shared/components/home/CourseCard";
import CourseCardSkeleton from "~/shared/components/home/CourseCardSkeleton";
import { useListCourses } from "~/shared/hooks/queries/courses/useListCourses";

export const Home = () => {
  const { t } = useTranslation();
  const { data: courses, isLoading } = useListCourses();

  return (
    <div className='flex h-screen flex-col'>
      <div className='flex flex-col gap-8 bg-gray-50 px-12 py-10'>
        <div className='flex flex-row items-center gap-2 rounded-xl border border-warning-500 bg-warning-50 p-4 text-warning-900 shadow-md xl1280:hidden'>
          <InfoIcon />
          <Text className='xl1280:hidden'>{t("ui.dimension")}</Text>
        </div>
        <div className='flex flex-row items-center gap-2'>
          <h1 className='font-tobias text-4xl'>{t("welcomeMessage.title")}</h1>
          <WavingHandIcon />
        </div>

        <p className='text-lg text-gray-600'>
          {t("welcomeMessage.description")}
        </p>
      </div>
      <div className='flex h-full flex-row justify-between bg-neutral-gray'>
        <div className='grid w-2/3 grid-cols-2 gap-4 px-12 py-7'>
          {!isLoading ? (
            courses?.map((course) => <CourseCard key={course.id} {...course} />)
          ) : (
            <CourseCardSkeleton />
          )}
        </div>
        <AboutUsSection />
      </div>
    </div>
  );
};
