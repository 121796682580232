import { GingerPersonSVG } from "~/shared/components/icons";
import BlackPersonSVG from "~/shared/components/icons/BlackPersonSVG";
import BlondPersonSVG from "~/shared/components/icons/BlondePersonSVG";
import OldPersonSVG from "~/shared/components/icons/OldPersonSVG";
import WhitePersonSVG from "~/shared/components/icons/WhitePersonSVG";

export const ACTIVITY_IDS = {
  OLD_PERSON: "renalPhysiology.chapter3.module4.activity6.title",
  BLOND_PERSON: "renalPhysiology.chapter3.module4.activity7.title",
  GINGER_PERSON: "renalPhysiology.chapter3.module4.activity8.title",
  BLACK_PERSON: "renalPhysiology.chapter3.module4.activity9.title",
  WHITE_PERSON: "renalPhysiology.chapter3.module4.activity10.title",
} as const;

export const PERSON_SVG: Record<
  string,
  React.FC<{ className: string; sliderHeight: number; fillColor: string }>
> = {
  [ACTIVITY_IDS.OLD_PERSON]: OldPersonSVG,
  [ACTIVITY_IDS.BLOND_PERSON]: BlondPersonSVG,
  [ACTIVITY_IDS.GINGER_PERSON]: GingerPersonSVG,
  [ACTIVITY_IDS.BLACK_PERSON]: BlackPersonSVG,
  [ACTIVITY_IDS.WHITE_PERSON]: WhitePersonSVG,
};
