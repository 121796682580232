export const DIRECTIONS = {
  top: "top",
  right: "right",
  left: "left",
  down: "down",
} as const;

export type Directions = (typeof DIRECTIONS)[keyof typeof DIRECTIONS];

export const getArrowDirectionClass = (direction: Directions) => {
  switch (direction) {
    case DIRECTIONS.right:
      return "transform rotate-90";
    case DIRECTIONS.down:
      return "transform rotate-180";
    case DIRECTIONS.left:
      return "transform -rotate-90";
    default:
      return "";
  }
};

export const getChevronDirectionClass = (direction: Directions) => {
  switch (direction) {
    case DIRECTIONS.right:
      return "transform rotate-180";
    case DIRECTIONS.top:
      return "transform rotate-90";
    case DIRECTIONS.down:
      return "transform -rotate-90";
    default:
      return "";
  }
};
