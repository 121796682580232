import { create } from "zustand";

export interface UiStore {
  isCourseDrawerOpen: boolean;
  setIsCourseDrawerOpen(isCourseDrawerOpen: boolean): void;
}

export const useUiStore = create<UiStore>()((set) => ({
  isCourseDrawerOpen: false,
  setIsCourseDrawerOpen: (isCourseDrawerOpen: boolean) => {
    set({ isCourseDrawerOpen });
  },
}));
