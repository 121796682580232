import type { SVGProps } from "~/constants";

export const EmptyPictureIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M11.0833 1.75H2.91667C2.27233 1.75 1.75 2.27233 1.75 2.91667V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H11.0833C11.7277 12.25 12.25 11.7277 12.25 11.0833V2.91667C12.25 2.27233 11.7277 1.75 11.0833 1.75Z'
      stroke='#6C798B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.2487 6.41668C5.89303 6.41668 6.41536 5.89434 6.41536 5.25001C6.41536 4.60568 5.89303 4.08334 5.2487 4.08334C4.60437 4.08334 4.08203 4.60568 4.08203 5.25001C4.08203 5.89434 4.60437 6.41668 5.2487 6.41668Z'
      stroke='#6C798B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.25 8.75L10.4498 6.94983C10.2311 6.73111 9.93436 6.60825 9.625 6.60825C9.31564 6.60825 9.01895 6.73111 8.80017 6.94983L3.5 12.25'
      stroke='#6C798B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
