import { t } from "i18next";

export const getRandomErrorMessage = () => {
  const errorMessages = t("errorMessages", { returnObjects: true });
  const errorCount = Object.values(errorMessages).length;
  const randomErrorMessage = t(
    `errorMessages.error${Math.floor(Math.random() * errorCount) + 1}`,
  );
  return randomErrorMessage;
};
