import React from "react";

interface HighlightedTextProps {
  text: string;
}

const HighlightedText = ({ text }: HighlightedTextProps) => {
  const regex = /~(.*?)~/g;

  const parts = text.split(regex);

  return (
    <div className='text-neutral-black'>
      {parts.map((part, index) => {
        const isHighlighted = index % 2 !== 0;
        return isHighlighted ? (
          <span
            key={index}
            className='mb-2 mr-1 inline-block rounded bg-primary-30 p-2'
          >
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        );
      })}
    </div>
  );
};

export default HighlightedText;
