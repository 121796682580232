import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { tv } from "tailwind-variants";
import type { VariantProps } from "tailwind-variants";

const buttonVariants = tv({
  base: "focus-visible:ring-ring inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-bold text-white transition-colors focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-100",
  variants: {
    variant: {
      default: "bg-neutral-black hover:bg-neutral-black-hover",
      error: "bg-red-500 hover:bg-red-700",
      info: "bg-blue-500 hover:bg-blue-700",
      success: "bg-green-500 hover:bg-green-700",
      underline:
        "text-neutral-black underline focus-visible:text-primary-900 focus-visible:ring-0",
      outline:
        "border-2 border-neutral-black text-neutral-black focus-visible:ring-neutral-black/20",
      secondary:
        "border-2 border-neutral-black bg-white text-neutral-black hover:bg-neutral-black hover:text-white",
      link: "border-0 bg-transparent leading-5 text-neutral-black underline",
    },
    size: {
      default: "min-w-48 px-4 py-2 text-base",
      sm: "px-4 py-2 text-sm",
      icon: "h-10 w-10",
    },
    disabled: {
      true: "bg-disabled hover:bg-disabled",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
    disabled: false,
  },
});

export interface ButtonProps
  extends React.ComponentProps<"button">,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<React.ElementRef<"button">, ButtonProps>(
  ({ className, variant, size, disabled, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={buttonVariants({ variant, size, disabled, className })}
        ref={ref}
        {...props}
        disabled={disabled}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
