import type { UniqueIdentifier } from "@dnd-kit/core";

import type { SlotType } from "~/api/getDragActivity";
import { tw } from "~/shared/utils";
import { Draggable } from "../Draggable";
import { Dropzone } from "./Dropzone";

interface DropzoneSlotPropsType {
  dropzoneIdsWithError: string[];
  slot: SlotType;
  draggablePositions: Record<string, UniqueIdentifier | null>;
  dropzonePositions: Record<string, string>;
  boxClassName: string;
  findDraggableLabelToRender: (id: number) => string;
  draggableIdsWithError: string[];
  type: number;
  dragIcons?: Record<string, JSX.Element>;
}

export const DropzoneSlot = ({
  slot,
  dropzoneIdsWithError,
  dropzonePositions,
  draggablePositions,
  boxClassName,
  findDraggableLabelToRender,
  draggableIdsWithError,
  type,
  dragIcons,
}: DropzoneSlotPropsType) => {
  const draggableIdToRender = Object.keys(draggablePositions)?.find(
    (draggableId) => draggablePositions[draggableId] === slot.id,
  );
  return (
    <Dropzone
      key={slot.id}
      id={slot.id}
      className={tw(
        boxClassName,
        "absolute",
        dropzonePositions[slot.code],
        dropzoneIdsWithError.includes(`${slot.id}`) && "border-red-600",
      )}
    >
      {draggableIdToRender && (
        <Draggable
          draggableId={draggableIdToRender}
          option={findDraggableLabelToRender(Number(draggableIdToRender))}
          className={tw(
            boxClassName,
            draggableIdsWithError.includes(draggableIdToRender) &&
              "border-red-600",
          )}
          dragType={type}
          dragIcons={dragIcons}
        />
      )}
    </Dropzone>
  );
};
