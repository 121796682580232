import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { SliderActivity } from "~/api/getSliderActivity";
import { ActivityLayout } from "~/layout/ActivityLayout";
import { getRandomErrorMessage } from "~/shared/utils/activities/getRandomErrorMessage";
import { SliderContent } from "./SliderContent";
import { validateAnswer } from "./validateAnswer";

interface DoublePersonSliderProps {
  activityData: SliderActivity;
}

export const DoublePersonSlider = ({
  activityData,
}: DoublePersonSliderProps) => {
  const [waterHeight, setWaterHeight] = useState(0);
  const [saltHeight, setSaltHeight] = useState(0);
  const [hasSuccessMessage, setHasSuccessMessage] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const waterSlider = activityData?.sliders.find((slider) =>
      slider.title.includes("slider1"),
    );
    const saltSlider = activityData?.sliders.find((slider) =>
      slider.title.includes("slider2"),
    );

    if (waterSlider?.initial) setWaterHeight(Number(waterSlider.initial));
    if (saltSlider?.initial) setSaltHeight(Number(saltSlider.initial));
  }, [activityData]);

  const { t } = useTranslation();

  if (!activityData) return <p>No activity data.</p>;
  const { sliders, title, description, explanation } = activityData;
  const waterSlider = sliders.find((slider) =>
    slider.title.includes("slider1"),
  );
  const saltSlider = sliders.find((slider) => slider.title.includes("slider2"));
  if (!waterSlider || !saltSlider) return null;
  const resetActivityState = () => {
    setWaterHeight(Number(waterSlider?.initial ?? 0));
    setSaltHeight(Number(saltSlider?.initial ?? 0));
    setHasErrorMessage(false);
    setHasSuccessMessage(false);
  };

  const checkAnswer = () => {
    const isCorrect = validateAnswer({
      waterSlider,
      saltSlider,
      activityTitle: activityData.title,
      saltHeight,
      waterHeight,
    });
    setHasSuccessMessage(isCorrect);
    setHasErrorMessage(!isCorrect);
    if (!isCorrect) {
      setErrorMessage(getRandomErrorMessage());
    }
  };

  return (
    <ActivityLayout
      title={t(title)}
      successMessage={hasSuccessMessage ? t(explanation) : ""}
      hasError={hasErrorMessage}
      errorMessage={errorMessage}
      onCheckClick={checkAnswer}
      isCheckDisabled={false}
      hasAbsolutePositionExplanation
      resetActivityState={resetActivityState}
    >
      <SliderContent
        description={description}
        saltSlider={saltSlider}
        waterSlider={waterSlider}
        waterHeight={waterHeight}
        saltHeight={saltHeight}
        setWaterHeight={setWaterHeight}
        setSaltHeight={setSaltHeight}
        activityTitle={title}
      />
    </ActivityLayout>
  );
};
