import { api } from "./axios";

export interface SliderType {
  id: string;
  title: string;
  description: string;
  minimum: string;
  maximum: string;
  answer_minimum: string;
  answer_maximum: string;
  initial: string | null;
}

export interface SliderActivity {
  id: number;
  title: string;
  description: string;
  explanation: string;
  module_id: number;
  order: number;
  sliders: SliderType[];
}

export interface GetSliderActivityResponse {
  data: SliderActivity;
}

export const getSliderActivity = async (activityId: string) => {
  const { data } = await api.get<GetSliderActivityResponse>(
    `/activities/slider/${activityId}`,
  );
  return data;
};
