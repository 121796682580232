import React from "react";

import { tw } from "~/shared/utils";

interface ProgressBarSkeletonProps {
  className?: string;
  progressClassName?: string;
}

export const ProgressBarSkeleton = ({
  className,
  progressClassName,
}: ProgressBarSkeletonProps) => {
  return (
    <div className={tw("h-2.5 w-full", className)}>
      <div
        className={tw(
          "h-2.5 w-full animate-pulse rounded-full bg-gray-200",
          progressClassName,
        )}
      />
    </div>
  );
};

export default ProgressBarSkeleton;
