import { useQuery } from "@tanstack/react-query";

import { getChoiceActivity } from "~/api/courses/activities";

export const useGetChoice = (id?: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getChoiceActivity(id ?? ""),
    queryKey: ["getChoice", id],
    enabled: !!id,
  });

  return { data, isLoading };
};
