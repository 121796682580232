import type { Renderable } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { tw } from "~/shared/utils";
import { Text } from "../..";
import { CheckIcon } from "../../icons";

interface SuccessToastProps {
  message: Renderable;
}

export const SuccessToast = ({ message }: SuccessToastProps) => {
  const { t } = useTranslation();
  return (
    <div className={tw("flex gap-1 rounded-lg bg-success-50 p-4 shadow-md")}>
      <CheckIcon />
      <div>
        <Text className='ml-2 font-semibold text-success-800'>
          {t("ui.success")} 💪
        </Text>
        <Text className='text-success-950'>{message}</Text>
      </div>
    </div>
  );
};
