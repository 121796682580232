import type { SVGProps } from "~/constants";

export const SaltIcon = ({ className, style }: SVGProps) => (
  <svg
    viewBox='0 0 45 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    style={style}
  >
    <rect x='1.72266' y='1' width='42' height='42' rx='21' fill='#F8F8F8' />
    <rect
      x='1.72266'
      y='1'
      width='42'
      height='42'
      rx='21'
      stroke='#EDEEF1'
      strokeWidth='2'
    />
    <path
      d='M31.0595 29.5017L28.4019 16.3763C28.8068 16.1608 29.0854 15.7399 29.0854 15.2494V13.5606C29.0854 11.594 27.4913 10 25.5243 10H19.8343C17.8682 10 16.2742 11.594 16.2742 13.5606V15.2494C16.2742 15.7714 16.5875 16.2191 17.036 16.419L14.3872 29.5017C14.3365 29.7492 14.3125 29.9984 14.3125 30.2463C14.3125 31.1051 14.6071 31.9462 15.1598 32.6226C15.8727 33.4945 16.9399 34 18.0658 34H27.381C28.5069 34 29.5732 33.4945 30.2861 32.6231C30.8388 31.9462 31.1343 31.1051 31.1343 30.2463C31.1343 29.9984 31.1094 29.7487 31.0595 29.5017ZM28.8753 31.4687C28.5078 31.9173 27.9604 32.1772 27.381 32.1772H18.0658C17.4864 32.1772 16.9381 31.9173 16.5714 31.4687C16.2866 31.1207 16.1353 30.6881 16.1353 30.2463C16.1353 30.119 16.1478 29.9908 16.1736 29.8636L18.8722 16.5311H26.5737L29.2733 29.8636C29.2982 29.9904 29.3115 30.1185 29.3115 30.2463C29.3115 30.6881 29.1593 31.1207 28.8753 31.4687Z'
      fill='#8A96A6'
    />
    <path
      d='M18.6395 27.132C18.2283 27.132 17.8945 27.4653 17.8945 27.8774C17.8945 28.2894 18.2283 28.6228 18.6395 28.6228C19.0516 28.6228 19.3853 28.2895 19.3853 27.8774C19.3853 27.4652 19.0516 27.132 18.6395 27.132Z'
      fill='#8A96A6'
    />
    <path
      d='M21.409 27.132C20.9978 27.132 20.6641 27.4653 20.6641 27.8774C20.6641 28.2894 20.9978 28.6228 21.409 28.6228C21.8211 28.6228 22.1549 28.2895 22.1549 27.8774C22.1549 27.4652 21.8211 27.132 21.409 27.132Z'
      fill='#8A96A6'
    />
    <path
      d='M24.1785 27.132C23.7673 27.132 23.4336 27.4653 23.4336 27.8774C23.4336 28.2894 23.7673 28.6228 24.1785 28.6228C24.5906 28.6228 24.9244 28.2895 24.9244 27.8774C24.9244 27.4652 24.5906 27.132 24.1785 27.132Z'
      fill='#8A96A6'
    />
    <path
      d='M26.9481 27.132C26.536 27.132 26.2031 27.4653 26.2031 27.8774C26.2031 28.2894 26.536 28.6228 26.9481 28.6228C27.3602 28.6228 27.694 28.2895 27.694 27.8774C27.694 27.4652 27.3602 27.132 26.9481 27.132Z'
      fill='#8A96A6'
    />
    <path
      d='M20.0028 26.4809C20.4149 26.4809 20.7486 26.1476 20.7486 25.7355C20.7486 25.3234 20.4149 24.9901 20.0028 24.9901C19.5916 24.9901 19.2578 25.3234 19.2578 25.7355C19.2578 26.1476 19.5916 26.4809 20.0028 26.4809Z'
      fill='#8A96A6'
    />
    <path
      d='M22.7723 26.4809C23.1844 26.4809 23.5182 26.1476 23.5182 25.7355C23.5182 25.3234 23.1844 24.9901 22.7723 24.9901C22.3611 24.9901 22.0273 25.3234 22.0273 25.7355C22.0273 26.1476 22.3611 26.4809 22.7723 26.4809Z'
      fill='#8A96A6'
    />
    <path
      d='M25.538 26.4809C25.95 26.4809 26.2838 26.1476 26.2838 25.7355C26.2838 25.3234 25.95 24.9901 25.538 24.9901C25.1268 24.9901 24.793 25.3234 24.793 25.7355C24.793 26.1476 25.1268 26.4809 25.538 26.4809Z'
      fill='#8A96A6'
    />
    <path
      d='M20.0028 29.1827C19.5916 29.1827 19.2578 29.516 19.2578 29.9281C19.2578 30.3402 19.5916 30.6735 20.0028 30.6735C20.4149 30.6735 20.7486 30.3403 20.7486 29.9281C20.7486 29.516 20.4149 29.1827 20.0028 29.1827Z'
      fill='#8A96A6'
    />
    <path
      d='M22.7723 29.1827C22.3611 29.1827 22.0273 29.516 22.0273 29.9281C22.0273 30.3402 22.3611 30.6735 22.7723 30.6735C23.1844 30.6735 23.5182 30.3403 23.5182 29.9281C23.5182 29.516 23.1844 29.1827 22.7723 29.1827Z'
      fill='#8A96A6'
    />
    <path
      d='M25.538 29.1827C25.1268 29.1827 24.793 29.516 24.793 29.9281C24.793 30.3402 25.1268 30.6735 25.538 30.6735C25.95 30.6735 26.2838 30.3403 26.2838 29.9281C26.2838 29.516 25.95 29.1827 25.538 29.1827Z'
      fill='#8A96A6'
    />
  </svg>
);
