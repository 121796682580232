import React from "react";

import Spinner from "../Spinner/Spinner";

const LoaderSection = () => {
  return (
    <div className='flex h-full w-full items-center justify-center'>
      <Spinner />
    </div>
  );
};

export default LoaderSection;
