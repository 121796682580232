import { t } from "i18next";

// export const getPasswordRules = (password: string) => [
//   { label: "8 characters", isMet: password.length >= 8 },
//   { label: "1 lowercase letter", isMet: /[a-z]/.test(password) },
//   { label: "1 uppercase letter", isMet: /[A-Z]/.test(password) },
//   { label: "1 number", isMet: /\d/.test(password) },
//   {
//     label: "1 special character (e.g. ! _ @ # ?)",
//     isMet: /[^A-Za-z0-9]/.test(password),
//   },
// ];

export const getPasswordRules = (password: string) => [
  { label: t("passwordRules.8Characters"), isMet: password.length >= 8 },
  { label: t("passwordRules.lowercaseLetter"), isMet: /[a-z]/.test(password) },
  { label: t("passwordRules.uppercaseLetter"), isMet: /[A-Z]/.test(password) },
  { label: t("passwordRules.number"), isMet: /\d/.test(password) },
  {
    label: t("passwordRules.specialCharacter", {
      example: "! _ @ # ?",
    }),
    isMet: /[^A-Za-z0-9]/.test(password),
  },
];
