import { useQuery } from "@tanstack/react-query";

import { getVideoActivity } from "~/api/courses/activities";

export const useGetVideoActivity = (id?: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getVideoActivity(id ?? ""),
    queryKey: ["getVideoActivity", id],
    enabled: !!id,
  });

  return { data, isLoading };
};
