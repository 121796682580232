import React from "react";

import Skeleton from "~/shared/components/ui/Skeleton/Skeleton";

export const HeaderSkeleton = () => {
  return (
    <div>
      <div className='relative flex flex-col gap-8 overflow-hidden bg-gray-50 px-14 py-8'>
        <div className='z-30 flex flex-row gap-2'>
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} className='h-4 w-24 rounded-md' />
          ))}
        </div>

        <div className='z-10 flex flex-row items-center gap-6'>
          <Skeleton className='h-6 w-40 rounded-full' />
          <div className='flex min-w-fit flex-row items-center gap-2'>
            <Skeleton className='size-6 rounded-md' />
            <Skeleton className='size-6 rounded-md' />
          </div>
        </div>
      </div>
    </div>
  );
};
