import type { SliderType } from "~/api/getSliderActivity";
import { ACTIVITY_IDS } from "./constants";

interface ValidateAnswerProps {
  saltSlider: SliderType;
  waterSlider: SliderType;
  activityTitle?: string;
  saltHeight: number;
  waterHeight: number;
}

export const validateAnswer = ({
  saltSlider,
  waterSlider,
  activityTitle,
  saltHeight,
  waterHeight,
}: ValidateAnswerProps) => {
  let isCorrect = false;
  const saltSliderMinAnswer = Number(saltSlider.answer_minimum);
  const saltSliderMaxAnswer = Number(saltSlider.answer_maximum);
  const waterSliderMinAnswer = Number(waterSlider.answer_minimum);
  const waterSliderMaxAnswer = Number(waterSlider.answer_maximum);
  //console.log(saltHeight, saltSliderMinAnswer, saltSliderMaxAnswer, waterHeight, waterSliderMinAnswer, waterSliderMaxAnswer);

  switch (activityTitle) {
    case ACTIVITY_IDS.OLD_PERSON:
      isCorrect =
        saltHeight > waterHeight &&
        saltHeight >= saltSliderMinAnswer &&
        saltHeight <= saltSliderMaxAnswer &&
        waterHeight >= waterSliderMinAnswer &&
        waterHeight <= waterSliderMaxAnswer;
      break;
    case ACTIVITY_IDS.BLOND_PERSON:
      isCorrect =
        saltHeight < waterHeight &&
        saltHeight >= saltSliderMinAnswer &&
        saltHeight <= saltSliderMaxAnswer &&
        waterHeight >= waterSliderMinAnswer &&
        waterHeight <= waterSliderMaxAnswer;
      break;

    case ACTIVITY_IDS.GINGER_PERSON:
      isCorrect =
        saltHeight >= saltSliderMinAnswer &&
        saltHeight <= saltSliderMaxAnswer &&
        waterHeight >= waterSliderMinAnswer &&
        waterHeight <= waterSliderMaxAnswer;
      break;

    case ACTIVITY_IDS.BLACK_PERSON:
      isCorrect =
        waterHeight < saltHeight && saltHeight < Number(saltSlider.initial);
      break;

    case ACTIVITY_IDS.WHITE_PERSON:
      isCorrect =
        saltHeight < Number(saltSlider.initial) &&
        waterHeight < Number(waterSlider.initial) &&
        saltHeight == waterHeight;
      break;

    default:
      isCorrect = false;
      break;
  }

  return isCorrect;
};
