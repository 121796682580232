import { useDroppable } from "@dnd-kit/core";

export const Dropzone = ({
  id,
  className,
  children,
}: {
  id: number;
  className: string;
  children: React.ReactNode;
}) => {
  const { setNodeRef } = useDroppable({ id });
  return (
    <div ref={setNodeRef} className={className}>
      {children}
    </div>
  );
};
