import type { SVGProps } from "~/constants";

export const WaterIcon = ({ className, style }: SVGProps) => (
  <svg
    viewBox='0 0 45 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    style={style}
  >
    <rect x='1.72266' y='1' width='42' height='42' rx='21' fill='#EFF6FF' />
    <rect
      x='1.72266'
      y='1'
      width='42'
      height='42'
      rx='21'
      stroke='#DBEAFE'
      strokeWidth='2'
    />
    <path
      d='M22.7183 14L27.6708 18.9525C28.6502 19.9313 29.3173 21.1786 29.5878 22.5365C29.8583 23.8945 29.7199 25.3022 29.1902 26.5816C28.6606 27.8609 27.7634 28.9545 26.6121 29.7238C25.4609 30.4932 24.1073 30.9039 22.7227 30.9039C21.338 30.9039 19.9844 30.4932 18.8332 29.7238C17.6819 28.9545 16.7847 27.8609 16.2551 26.5816C15.7254 25.3022 15.587 23.8945 15.8575 22.5365C16.128 21.1786 16.7951 19.9313 17.7745 18.9525L22.7183 14Z'
      stroke='#3B8BF6'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
