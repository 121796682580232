import { useEffect, useState } from "react";
import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { useTranslation } from "react-i18next";

import type { DragAndDropActivityResponse } from "~/api/getDragActivity";
import { ACTIVITY_STATUS } from "../components/Activities/dragAndDrop";
import { getRandomErrorMessage } from "../utils/activities/getRandomErrorMessage";

type ActivityStatus = (typeof ACTIVITY_STATUS)[keyof typeof ACTIVITY_STATUS];

interface UseDragAndDropResult {
  draggablePositions: Record<string, UniqueIdentifier | null>;
  activeId: UniqueIdentifier | null;
  draggableIdsWithError: string[];
  dropzoneIdsWithError: string[];
  activityStatus: ActivityStatus;
  handleDragStart: (event: DragStartEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  areAnswersCorrect: () => void;
  findDraggingOption: () => string;
  resetDragAndDropState: () => void;
  errorMessage: string;
}

export const useMultiDragAndDrop = (
  activityData: DragAndDropActivityResponse["data"],
): UseDragAndDropResult => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [draggablePositions, setDraggablePositions] = useState<
    Record<string, UniqueIdentifier | null>
  >({});
  const [draggableIdsWithError, setDraggableIdsWithError] = useState<string[]>(
    [],
  );
  const [dropzoneIdsWithError, setDropzoneIdsWithError] = useState<string[]>(
    [],
  );
  const [activityStatus, setActivityStatus] =
    useState<ActivityStatus>("noAnswer");
  const [errorMessage, setErrorMessage] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (activityData) {
      const initialDraggablePositions = activityData.options.reduce(
        (acc: Record<string, null>, option: { id: number }) => {
          acc[option.id] = null;
          return acc;
        },
        {},
      );
      setDraggablePositions(initialDraggablePositions);
    }
  }, [activityData]);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(Number(event.active.id));
    setDraggableIdsWithError((prev) =>
      prev.filter((prevValue) => prevValue != event.active.id),
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    setDraggablePositions((prev) => {
      return { ...prev, [active.id]: over?.id ?? null };
    });
    setActiveId(null);
  };

  const areAnswersCorrect = () => {
    const draggableIdsWithError: string[] = [];
    Object.keys(draggablePositions).forEach((draggableId) => {
      const slotToCheck = activityData?.slots.find(
        (slot) => slot.id === draggablePositions[draggableId],
      );
      const isDraggablePositionCorrect = slotToCheck?.correctOptions.includes(
        Number(draggableId),
      );
      if (!isDraggablePositionCorrect) {
        draggableIdsWithError.push(draggableId);
      }
    });
    setDraggableIdsWithError(draggableIdsWithError);

    if (draggableIdsWithError.length === 0) {
      return setActivityStatus(ACTIVITY_STATUS.correct);
    }
    setActivityStatus(ACTIVITY_STATUS.incorrect);
    setErrorMessage(getRandomErrorMessage());
  };

  const findDraggingOption = () => {
    const draggingOption = activityData?.options.find(
      (option) => option.id === activeId,
    );
    return draggingOption?.value ? t(draggingOption.value) : "";
  };

  const resetDragAndDropState = () => {
    setActiveId(null);
    setDraggablePositions({});
    setDraggableIdsWithError([]);
    setDropzoneIdsWithError([]);
    setActivityStatus(ACTIVITY_STATUS.noAnswer);
  };
  return {
    draggablePositions,
    activeId,
    draggableIdsWithError,
    dropzoneIdsWithError,
    activityStatus,
    handleDragStart,
    handleDragEnd,
    areAnswersCorrect,
    findDraggingOption,
    resetDragAndDropState,
    errorMessage,
  };
};
