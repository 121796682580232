import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import type { DragAndDropActivityResponse } from "~/api/getDragActivity";
import { ActivityLayout } from "~/layout/ActivityLayout";
import { useMultiDragAndDrop } from "~/shared/hooks/useMultiDragAndDrop";
import { ACTIVITY_STATUS } from "../constants";
import { MultiDragAndDropArea } from "./MultiDragAndDropArea";

interface MultiDragAndDropProps {
  activityData: DragAndDropActivityResponse["data"];
}

export const MultiDragAndDrop = ({ activityData }: MultiDragAndDropProps) => {
  const { activityId } = useParams();
  const { t } = useTranslation();

  const {
    draggablePositions,
    activeId,
    draggableIdsWithError,
    dropzoneIdsWithError,
    handleDragStart,
    handleDragEnd,
    areAnswersCorrect,
    findDraggingOption,
    resetDragAndDropState,
    activityStatus,
    errorMessage,
  } = useMultiDragAndDrop(activityData);

  if (!activityData || !activityId) {
    return null;
  }
  return (
    <ActivityLayout
      title={activityData?.title}
      successMessage={
        activityStatus === ACTIVITY_STATUS.correct
          ? t(activityData.explanation)
          : ""
      }
      errorMessage={errorMessage}
      hasError={activityStatus === ACTIVITY_STATUS.incorrect}
      onCheckClick={areAnswersCorrect}
      isCheckDisabled={Object.keys(draggablePositions).every(
        (key) => draggablePositions[key] == null,
      )}
      resetActivityState={resetDragAndDropState}
      isMultiDragAndDrop
    >
      <MultiDragAndDropArea
        handleDragEnd={handleDragEnd}
        handleDragStart={handleDragStart}
        activityData={activityData}
        findDraggingOption={findDraggingOption}
        draggableIdsWithError={draggableIdsWithError}
        dropzoneIdsWithError={dropzoneIdsWithError}
        activeId={activeId}
        draggablePositions={draggablePositions}
      />
    </ActivityLayout>
  );
};
