export const DRAG_ONE_BOX_BASE_CLASS_NAME =
  "h-12 w-48 rounded border-2 border-lilac bg-lilacLight p-2 font-semibold ";

export const DRAG_TWO_BOX_BASE_CLASS_NAME =
  "h-12 w-[16.5rem] rounded border-2 border-lilac bg-lilacLight p-2 font-semibold";

export const DRAG_THREE_BOX_BASE_CLASS_NAME =
  "h-12 w-24 rounded border-2 border-lilac bg-lilacLight p-2 font-semibold";

export const DRAG_FOUR_BOX_BASE_CLASS_NAME = "h-24 w-20 p-2";
