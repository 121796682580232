import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Text } from "~/shared";
import CountdownTimer from "~/shared/components/ui/CountdownTimer/CountdownTimer";
import Spinner from "~/shared/components/ui/Spinner/Spinner";
import { useForgotPasswordMutation } from "~/shared/hooks/queries/useForgotPasswordMutation";

interface ForgotPasswordSentProps {
  email: string;
}
export const ForgotPasswordSent = ({ email }: ForgotPasswordSentProps) => {
  const { t } = useTranslation();
  const [linkResent, setLinkResent] = useState(true);
  const onSuccess = () => {
    setLinkResent(true);
  };

  const { mutate: forgotPasswordMutation, isPending } =
    useForgotPasswordMutation({ onSuccess });

  return (
    <div className='flex w-full flex-col gap-12'>
      <div className='flex flex-col items-center justify-center gap-12'>
        <div className='flex flex-col gap-4'>
          <Text className='text-gray-600'>{t("forgotPassword.checkSpam")}</Text>
          {linkResent ? (
            <CountdownTimer onComplete={() => setLinkResent(false)} />
          ) : (
            <Button
              onClick={() => forgotPasswordMutation({ email })}
              className='w-full font-semibold'
            >
              {isPending ? <Spinner /> : t("Resend Link")}
            </Button>
          )}
        </div>
        <Text>
          <Trans i18nKey={"forgotPassword.contactUs"}>
            Still need help
            <a
              href='mailto:sophia.ambruso@cuanschutz.edu'
              target='_blank'
              className='underline'
              rel='noreferrer'
            >
              Contact us
            </a>
            and we’ll sort it out as soon as possible
          </Trans>
        </Text>
      </div>
    </div>
  );
};
