import React from "react";
import { useTranslation } from "react-i18next";

import { DeviceNotSupportedBg } from "~/assets";
import { ABCKidneyLogoIcon } from "../icons";
import { Heading, Text } from "../ui";

const DeviceViewNotSupported = () => {
  const { t } = useTranslation();
  return (
    <div
      className='flex h-dvh w-full flex-col items-center justify-center gap-6 bg-primary-50 bg-cover bg-center bg-no-repeat md:hidden'
      style={{
        backgroundImage: `url(${DeviceNotSupportedBg})`,
      }}
    >
      <ABCKidneyLogoIcon className='w-52' />
      <Heading className='font-tobias font-normal'>
        {t("deviceNotSupported.hey")} 👋
      </Heading>
      <Text className='w-2/3 text-center text-2xl font-medium'>
        {t("deviceNotSupported.visitOnDesktop")}
      </Text>
      <Text className='w-2/3 text-center font-tobias text-2xl font-medium'>
        {t("deviceNotSupported.seeYouThere")}
      </Text>
    </div>
  );
};

export default DeviceViewNotSupported;
