import React from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shared";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";

interface SelectFormItemProps<TFormValues extends FieldValues> {
  name: Path<TFormValues>;
  label: string;
  placeholder?: string;
  control: Control<TFormValues>;
  options: readonly [string, ...string[]];
}

const SelectOption = ({ value }: { value: string }) => {
  return (
    <SelectItem key={value} value={value}>
      {value}
    </SelectItem>
  );
};

const SelectFormItem = <TFormValues extends FieldValues>({
  name,
  label,
  placeholder,
  control,
  options,
}: SelectFormItemProps<TFormValues>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <Select onValueChange={field.onChange}>
            <FormControl>
              <SelectTrigger>
                <SelectValue
                  className='text-red-600'
                  placeholder={placeholder}
                />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {options.map((option) => (
                <SelectOption key={option} value={option} />
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default SelectFormItem;
