import type { Course } from "~/types/courses";

export const getActivityPath = (
  course?: Course,
  chapterId?: string,
  moduleId?: string,
  activityId?: string,
  activityType?: string,
) => {
  const selectedChapter = course?.chapters.find((ch) => ch.id == chapterId);
  if (!selectedChapter) return null;

  const selectedModule = selectedChapter.modules.find(
    (mod) => mod.id == moduleId,
  );
  if (!selectedModule) return null;

  const selectedActivity = selectedModule.activities.find(
    (act) => act.type === activityType && act.id == activityId,
  );
  if (!selectedActivity) return null;

  return {
    activityPath: [
      `courses.${course?.title}`,
      `chapters.${selectedChapter.title}`,
      `modules.${selectedModule.title}`,
      `activities.types.${selectedActivity.type}`,
    ],
    activityLabel: `activities.${selectedActivity.label}`,
  };
};
