import React from "react";

import { tw } from "~/shared/utils";

interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min: number;
  max: number;
  className?: string;
}

export const Slider: React.FC<SliderProps> = ({
  value,
  onChange,
  min,
  max,
  className,
  ...props
}) => (
  <input
    type='range'
    min={min}
    max={max}
    value={value}
    onChange={onChange}
    className={tw(
      "accent h-3 w-full cursor-pointer appearance-none rounded-full bg-blue-100 accent-primary",
      className,
    )}
    {...props}
  />
);
