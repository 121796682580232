export const DRAG_AND_DROP_ONE_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter1.module1.activity4.slot1.code":
    "bottom-0 right-[140px]",
  "renalPhysiology.chapter1.module1.activity4.slot2.code":
    "bottom-1 left-[170px]",
  "renalPhysiology.chapter1.module1.activity4.slot3.code": "left-[170px] top-0",
  "renalPhysiology.chapter1.module1.activity4.slot4.code":
    "right-[140px] top-[55px] ",
};

export const DRAG_AND_DROP_TWO_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter1.module1.activity5.slot1.code":
    "top-[-5px] left-[110px] 1280:left-[110px]  ",
  "renalPhysiology.chapter1.module1.activity5.slot2.code":
    "bottom-[22px] left-[130px] 1280:bottom-[15px] 1280:left-[120px]",

  "renalPhysiology.chapter1.module1.activity5.slot3.code":
    "1280:top-[160px] 1280:right-[17px] top-[121px] right-[58px]",

  "renalPhysiology.chapter1.module1.activity5.slot4.code":
    "top-[25px] right-[165px]",

  "renalPhysiology.chapter1.module1.activity5.slot5.code":
    "1280:bottom-[-48px] bottom-[-26px] right-[159px]",
  "renalPhysiology.chapter1.module1.activity5.slot6.code":
    "1280:bottom-[95px] 1280:right-[20px] bottom-[84px] right-[58px]",
};

export const DRAG_AND_DROP_THREE_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter3.module1.activity5.slot1.code":
    "bottom-[75px] right-[296px]",
  "renalPhysiology.chapter3.module1.activity5.slot2.code":
    "top-[136px] right-[296px]",

  "renalPhysiology.chapter3.module1.activity5.slot3.code":
    "top-[82px] left-[210px] ",

  "renalPhysiology.chapter3.module1.activity5.slot4.code":
    "top-[193px] left-[210px]",

  "renalPhysiology.chapter3.module1.activity5.slot5.code":
    "top-[212px] right-[170px]",
};

export const DRAG_AND_DROP_FOUR_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter3.module2.activity6.slot1.code":
    "top-[-6px] left-[135px] ",
};

export const DRAG_AND_DROP_FIVE_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter3.module2.activity7.slot1.code":
    "top-[-6px] left-[135px] ",
};

export const DRAG_AND_DROP_SIX_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter3.module2.activity8.slot1.code":
    "top-[-6px] left-[135px] ",
};
