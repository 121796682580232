import type { SVGProps } from "~/constants";

export const TextActivityIcon = ({ className, ...props }: SVGProps) => (
  <svg
    className={className}
    {...props}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.00002 13.9259C11.2729 13.9259 13.9259 11.2729 13.9259 7.99999C13.9259 4.7271 11.2729 2.07407 8.00002 2.07407C4.72713 2.07407 2.0741 4.7271 2.0741 7.99999C2.0741 11.2729 4.72713 13.9259 8.00002 13.9259Z'
      stroke='#5FA28D'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.22229 7.99997L7.40748 9.18516L9.77785 6.81479'
      stroke='#5FA28D'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
