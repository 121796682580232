import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import type { z } from "zod";

import { Button, Form } from "~/shared";
import { useResetPasswordMutation } from "~/shared/hooks/queries/useResetPasswordMutation";
import { resetPasswordFormSchema } from "~/shared/schemas";
import Spinner from "../ui/Spinner/Spinner";
import PasswordInputFormItem from "../utils/PasswordInputFormItem";
import { PasswordRequirements } from "../utils/PasswordRequirements";

type ResetPasswordFormValuesType = z.infer<typeof resetPasswordFormSchema>;

const ResetPasswordForm = () => {
  const { token } = useParams();
  const { t } = useTranslation();

  const form = useForm<ResetPasswordFormValuesType>({
    resolver: zodResolver(resetPasswordFormSchema),
  });

  const { mutate: resetPasswordMutation, isPending } =
    useResetPasswordMutation();

  if (!token) return null;

  const onSubmit: SubmitHandler<ResetPasswordFormValuesType> = ({
    password,
  }) => {
    resetPasswordMutation({ password, token });
  };
  const { watch } = form;

  const password = watch("password") ?? "";
  const passwordConfirm = watch("passwordConfirm") ?? "";

  const areFieldsEmpty = !password || !passwordConfirm;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className='flex flex-col gap-8'
      >
        <div className='flex flex-col gap-4'>
          <PasswordInputFormItem
            name='password'
            control={form.control}
            label={t("resetPassword.newPassword")}
          />
          <PasswordInputFormItem
            name='passwordConfirm'
            control={form.control}
            label={t("resetPassword.confirmPassword")}
          />
        </div>
        <PasswordRequirements password={password} />
        <Button
          className='mt-4 w-full font-semibold'
          type='submit'
          disabled={areFieldsEmpty}
        >
          {isPending ? <Spinner /> : t("resetPassword.savePassword")}
        </Button>
      </form>
    </Form>
  );
};

export default ResetPasswordForm;
