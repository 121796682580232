import type { SVGProps } from "~/constants";

export const EyeIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M1.33337 8.00001C1.33337 8.00001 3.33337 3.33334 8.00004 3.33334C12.6667 3.33334 14.6667 8.00001 14.6667 8.00001C14.6667 8.00001 12.6667 12.6667 8.00004 12.6667C3.33337 12.6667 1.33337 8.00001 1.33337 8.00001Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
