import type { SVGProps } from "~/constants";

export const MultipleChoiceIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M5.62964 4.44446H13.3333'
      stroke='#FF549D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.62964 8H13.3333'
      stroke='#FF549D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.62964 11.5556H13.3333'
      stroke='#FF549D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.66675 4.44446H2.67341'
      stroke='#FF549D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.66675 8H2.67341'
      stroke='#FF549D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.66675 11.5556H2.67341'
      stroke='#FF549D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
