import type { UniqueIdentifier } from "@dnd-kit/core";
import { useTranslation } from "react-i18next";

import type { SlotType } from "~/api/getDragActivity";
import { tw } from "~/shared/utils";
import { Text } from "../../../ui";
import { Draggable } from "../Draggable";
import { DROPZONE_ICONS } from "./MultiDragAndDropConfigs";
import { MultiDropzone } from "./MultiDropzone";

interface DropzoneSlotPropsType {
  dropzoneIdsWithError: string[];
  slot: SlotType;
  draggablePositions: Record<string, UniqueIdentifier | null>;
  boxClassName: string;
  findDraggableLabelToRender: (id: number) => string;
  draggableIdsWithError: string[];
}

export const MultiDropzoneSlot = ({
  slot,
  dropzoneIdsWithError,
  draggablePositions,
  boxClassName,
  findDraggableLabelToRender,
  draggableIdsWithError,
}: DropzoneSlotPropsType) => {
  const { t } = useTranslation();

  const draggablesToRender = Object.keys(draggablePositions).filter(
    (draggableId) => draggablePositions[draggableId] === slot.id,
  );
  return (
    <MultiDropzone
      key={slot.id}
      id={slot.id}
      className={tw(
        "rounded bg-primary-50 p-2 font-semibold",
        "flex h-[22rem] w-[48%] flex-col gap-2",
        dropzoneIdsWithError.includes(`${slot.id}`) &&
          "border-2 border-red-600",
      )}
    >
      <div className='flex justify-center gap-2'>
        <Text className='text-center font-semibold'>{t(slot.code)}</Text>
        {DROPZONE_ICONS[slot.code]}
      </div>
      {draggablesToRender.map((dragId) => (
        <Draggable
          key={dragId}
          draggableId={dragId}
          option={findDraggableLabelToRender(Number(dragId))}
          className={tw(
            boxClassName,
            "w-full",
            draggableIdsWithError.includes(dragId) && "border-2 border-red-600",
          )}
        />
      ))}
    </MultiDropzone>
  );
};
