import React from "react";

import { CloseIcon, tw } from "~/shared";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <>
      {isOpen && (
        <div
          role='button'
          tabIndex={0}
          className='fixed inset-0 z-40 cursor-default bg-black bg-opacity-50'
          onClick={onClose}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              onClose();
            }
          }}
        />
      )}

      <div
        className={tw(
          "fixed right-0 top-0 z-50 h-screen w-[37.5rem] overflow-auto bg-white shadow-lg transition-transform duration-300",
          isOpen ? "translate-x-0" : "translate-x-full",
        )}
      >
        <div className='flex flex-col items-end gap-6 p-5'>
          <button onClick={onClose} className='w-fit'>
            <CloseIcon className='size-5 text-gray-500' />
          </button>
          {children}
        </div>
      </div>
    </>
  );
};
