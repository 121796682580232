import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import type { z } from "zod";

import { Button, Form, useSignUpMutation } from "~/shared";
import { GRADUATION_YEARS } from "~/shared/constants/signUp";
import { signUpFormSchema } from "~/shared/schemas";
import Spinner from "../ui/Spinner/Spinner";
import PasswordInputFormItem from "../utils/PasswordInputFormItem";
import { PasswordRequirements } from "../utils/PasswordRequirements";
import SelectFormItem from "../utils/SelectFormItem";

type SignUpFormValuesType = z.infer<typeof signUpFormSchema>;

const SignUpForm = () => {
  const { inviteCode } = useParams();
  const { t } = useTranslation();

  const form = useForm<SignUpFormValuesType>({
    resolver: zodResolver(signUpFormSchema),
  });

  const { mutate: signUpMutation, isPending } = useSignUpMutation();

  if (!inviteCode) return null;

  const onSubmit: SubmitHandler<SignUpFormValuesType> = ({
    password,
    gradYear,
  }) => {
    signUpMutation({ password, gradYear, inviteCode });
  };
  const { watch } = form;

  const gradYear = watch("gradYear") ?? "";
  const password = watch("password") ?? "";
  const passwordConfirm = watch("passwordConfirm") ?? "";

  const areFieldsEmpty = !gradYear || !password || !passwordConfirm;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className='flex flex-col gap-8'
      >
        <div className='flex flex-col gap-4'>
          <SelectFormItem
            control={form.control}
            name='gradYear'
            placeholder={t("signUp.enterGradYear")}
            label={t("signUp.expectedGradYear")}
            options={GRADUATION_YEARS}
          />
          <PasswordInputFormItem
            name='password'
            control={form.control}
            label={t("signUp.createPassword")}
          />
          <PasswordInputFormItem
            name='passwordConfirm'
            control={form.control}
            label={t("signUp.confirmPassword")}
            placeholder={t("signUp.confirmPassword")}
          />
        </div>
        <PasswordRequirements password={password} />
        <Button
          className='mt-4 w-full font-semibold'
          type='submit'
          disabled={areFieldsEmpty}
        >
          {isPending ? <Spinner /> : t("signUp.value")}
        </Button>
      </form>
    </Form>
  );
};

export default SignUpForm;
