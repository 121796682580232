import {
  DRAG_AND_DROP_FIVE_DROPZONE_POSITIONS,
  DRAG_AND_DROP_FOUR_DROPZONE_POSITIONS,
  DRAG_AND_DROP_ONE_DROPZONE_POSITIONS,
  DRAG_AND_DROP_SIX_DROPZONE_POSITIONS,
  DRAG_AND_DROP_THREE_DROPZONE_POSITIONS,
  DRAG_AND_DROP_TWO_DROPZONE_POSITIONS,
} from "~/shared/utils/activities/dropzonePositions";
import {
  DRAG_FOUR_BOX_BASE_CLASS_NAME,
  DRAG_ONE_BOX_BASE_CLASS_NAME,
  DRAG_THREE_BOX_BASE_CLASS_NAME,
  DRAG_TWO_BOX_BASE_CLASS_NAME,
} from "~/shared/utils/activities/dropzoneStyles";
import { DragDropTypes } from "~/types/dragAndDrop";
import {
  D5WDragIcon,
  GirlDragIcon,
  GirlDragIcon2,
  GirlDragIcon3,
  IsotonicDragIcon,
  LargeNephronDragIcon,
  NephronDragIcon,
  PersonDragIcon,
  PlasmaDragIcon,
} from "../../../icons";

interface DragAndDropData {
  dropzonePositions: Record<string, string>;
  dropzoneStyle: string;
  image: JSX.Element;
  type: number;
  dragIcons?: Record<string, JSX.Element>;
  hasAbsolutePositionExplanation: boolean;
}

export const dragAndDropConfigs: Record<string, DragAndDropData> = {
  1: {
    dropzonePositions: DRAG_AND_DROP_ONE_DROPZONE_POSITIONS,
    dropzoneStyle: DRAG_ONE_BOX_BASE_CLASS_NAME,
    image: <NephronDragIcon className='h-96 w-full' />,
    type: DragDropTypes.LEFT_DRAGGABLES,
    hasAbsolutePositionExplanation: false,
  },
  2: {
    dropzonePositions: DRAG_AND_DROP_TWO_DROPZONE_POSITIONS,
    dropzoneStyle: DRAG_TWO_BOX_BASE_CLASS_NAME,
    image: <LargeNephronDragIcon className='mt-5 h-72 w-full 1280:h-96' />,
    type: DragDropTypes.LEFT_DRAGGABLES,
    hasAbsolutePositionExplanation: false,
  },
  3: {
    dropzonePositions: DRAG_AND_DROP_THREE_DROPZONE_POSITIONS,
    dropzoneStyle: DRAG_THREE_BOX_BASE_CLASS_NAME,
    image: <PersonDragIcon className='h-96 w-full' />,
    type: DragDropTypes.LEFT_DRAGGABLES,
    hasAbsolutePositionExplanation: true,
  },
  4: {
    dropzonePositions: DRAG_AND_DROP_FOUR_DROPZONE_POSITIONS,
    dropzoneStyle: DRAG_FOUR_BOX_BASE_CLASS_NAME,
    image: <GirlDragIcon className='h-72 w-full 1280:h-96' />,
    type: DragDropTypes.RIGHT_DRAGGABLES,
    dragIcons: {
      "renalPhysiology.chapter3.module2.activity6.option1.value": (
        <IsotonicDragIcon key={"isotonic"} />
      ),
      "renalPhysiology.chapter3.module2.activity6.option2.value": (
        <PlasmaDragIcon key={"plasma"} />
      ),
      "renalPhysiology.chapter3.module2.activity6.option3.value": (
        <D5WDragIcon key={"d5w"} />
      ),
    },
    hasAbsolutePositionExplanation: true,
  },
  5: {
    dropzonePositions: DRAG_AND_DROP_FIVE_DROPZONE_POSITIONS,
    dropzoneStyle: DRAG_FOUR_BOX_BASE_CLASS_NAME,
    image: <GirlDragIcon2 className='h-72 w-full 1280:h-96' />,
    type: DragDropTypes.RIGHT_DRAGGABLES,
    dragIcons: {
      "renalPhysiology.chapter3.module2.activity7.option1.value": (
        <D5WDragIcon key={"d5w"} />
      ),
      "renalPhysiology.chapter3.module2.activity7.option2.value": (
        <PlasmaDragIcon key={"plasma"} />
      ),
      "renalPhysiology.chapter3.module2.activity7.option3.value": (
        <IsotonicDragIcon key={"isotonic"} />
      ),
    },
    hasAbsolutePositionExplanation: true,
  },
  6: {
    dropzonePositions: DRAG_AND_DROP_SIX_DROPZONE_POSITIONS,
    dropzoneStyle: DRAG_FOUR_BOX_BASE_CLASS_NAME,
    image: <GirlDragIcon3 className='h-72 w-full 1280:h-96' />,
    type: DragDropTypes.RIGHT_DRAGGABLES,
    dragIcons: {
      "renalPhysiology.chapter3.module2.activity8.option1.value": (
        <IsotonicDragIcon key={"isotonic"} />
      ),
      "renalPhysiology.chapter3.module2.activity8.option2.value": (
        <D5WDragIcon key={"d5w"} />
      ),
      "renalPhysiology.chapter3.module2.activity8.option3.value": (
        <PlasmaDragIcon key={"plasma"} />
      ),
    },
    hasAbsolutePositionExplanation: true,
  },
};
