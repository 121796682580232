import {
  ConnectingDuctSVG,
  DistalTubuleSVG,
  LoopOfHenleSVG,
  ProximalTubuleSVG,
} from "~/shared/components/icons";

export const MULTI_DRAG_BOX_CLASS_NAME =
  "h-11 text-sm rounded bg-lilacLight px-1 font-semibold ";

export const DROPZONE_ICONS: Record<string, JSX.Element> = {
  "renalPhysiology.chapter3.module8.activity6.slot1.code": (
    <ProximalTubuleSVG />
  ),
  "renalPhysiology.chapter3.module8.activity6.slot2.code": <LoopOfHenleSVG />,
  "renalPhysiology.chapter3.module8.activity6.slot3.code": <DistalTubuleSVG />,
  "renalPhysiology.chapter3.module8.activity6.slot4.code": (
    <ConnectingDuctSVG />
  ),
};
