import { useQuery } from "@tanstack/react-query";

import { getCourse } from "~/api/courses/course";

export const useGetCourse = (id?: string) => {
  const courseQuery = useQuery({
    queryFn: () => getCourse(id ?? ""),
    queryKey: ["getCourse", id],
    enabled: !!id,
  });

  return { courseQuery };
};
