import React from "react";
import { useTranslation } from "react-i18next";

import { WavingHandIcon } from "~/shared";
import SignUpForm from "~/shared/components/authorization/SignUpForm";
import { Heading } from "~/shared/components/ui/Heading/Heading";

export const SignUp = () => {
  const { t } = useTranslation();
  return (
    <div className='flex w-2/3 flex-col gap-12'>
      <div className='flex items-center justify-center gap-2'>
        <Heading className='font-tobias'>{t("signUp.welcome")}</Heading>
        <WavingHandIcon />
      </div>
      <SignUpForm />
    </div>
  );
};
