import React from "react";
import { useTranslation } from "react-i18next";

import { WavingHandIcon } from "~/shared";
import LoginForm from "~/shared/components/authorization/LoginForm";
import { Heading } from "~/shared/components/ui/Heading/Heading";

export const Login = () => {
  const { t } = useTranslation();
  return (
    <div className='flex w-2/3 flex-col gap-12'>
      <div className='flex items-center justify-center gap-2'>
        <Heading className='font-tobias'>{t("login.welcome")}</Heading>
        <WavingHandIcon />
      </div>
      <LoginForm />
    </div>
  );
};
