import { useMutation } from "@tanstack/react-query";
import { t } from "i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { resetPassword } from "~/api/auth";
import { ROUTES } from "~/router";

export const useResetPasswordMutation = () => {
  const navigate = useNavigate();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      navigate(ROUTES.login);
      toast.success(t("resetPassword.success"));
    },
    onError: () => {
      toast.error(t("resetPassword.error"));
    },
  });

  return { mutate, isPending, isError, error };
};
