import React from "react";
import { useOutlet } from "react-router-dom";

import DeviceViewNotSupported from "~/shared/components/utils/DeviceViewNotSupported";
import { Sidebar } from "./Sidebar";

export const Layout = () => {
  const outlet = useOutlet();

  return (
    <>
      <div className='hidden bg-neutral-background md:block'>
        <Sidebar />

        <main className='pl-20'>
          <div className='-ml-0.5'>{outlet}</div>
        </main>
      </div>
      <DeviceViewNotSupported />
    </>
  );
};
