import type { SVGProps } from "~/constants";

export const KidneyIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='242'
    height='236'
    viewBox='0 0 242 236'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M214.984 213.244C257.863 161.259 246.703 80.7429 190.059 33.4069C133.415 -13.929 52.7356 -10.1602 9.85709 41.825C-4.43575 59.1534 -1.89342 85.008 15.5356 99.573C30.8614 112.38 52.5946 112.194 67.3345 100.18C66.6125 115.6 76.8103 130.019 89.9275 136.438C98.2429 140.508 107.873 141.683 116.96 138.586C99.3652 164.389 104.141 200.119 128.828 220.748C154.971 242.596 193.545 239.236 214.984 213.244Z'
      fill='#EFF6FF'
    />
  </svg>
);
