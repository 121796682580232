import ConfettiExplosion from "react-confetti-explosion";
import { useTranslation } from "react-i18next";

import { EXPLOSION_FORCE } from "~/constants";
import { Button, tw, UndoIcon } from "~/shared";
import { CorrectAnswerResultText } from "~/shared/components/Activities/ui/CorrectAnswerResultText";
import { IncorrectAnswerResultText } from "~/shared/components/Activities/ui/IncorrectAnswerResultText";
import { NextAndPreviousButtons } from "~/shared/components/Activities/ui/NextAndPreviousButtons";

export const ActivityLayout = ({
  children,
  title,
  successMessage,
  hasError,
  onCheckClick,
  resetActivityState,
  containerClassName,
  errorMessage,
  isCheckDisabled = false,
  hasAbsolutePositionExplanation = false,
  isMultiDragAndDrop = false,
}: {
  children: JSX.Element;
  title: string;
  successMessage: string;
  hasError: boolean;
  onCheckClick: () => void;
  containerClassName?: string;
  errorMessage?: string;
  isCheckDisabled?: boolean;
  hasAbsolutePositionExplanation?: boolean;
  resetActivityState?: () => void;
  isMultiDragAndDrop?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className='flex h-full min-h-fit flex-col justify-between gap-4 bg-neutral-background p-8'>
      <div className='flex h-full flex-col gap-4'>
        <h1 className='text-2xl font-semibold'>{t(title)}</h1>
        <div
          className={tw(
            "relative flex h-full flex-col justify-center gap-10 overflow-scroll rounded-xl bg-white p-7",
            containerClassName,
          )}
        >
          {resetActivityState && (
            <Button
              className={tw(
                "absolute z-10 w-fit min-w-0 bg-primary-50",
                isMultiDragAndDrop ? "left-[29%] top-7" : "right-7 top-7",
              )}
              onClick={resetActivityState}
            >
              <UndoIcon />
            </Button>
          )}
          {successMessage && (
            <ConfettiExplosion
              className='absolute left-1/2 top-0'
              force={EXPLOSION_FORCE}
            />
          )}
          <div className='flex flex-col gap-4'>{children}</div>
          {(successMessage || hasError) && (
            <div
              className={tw(
                "flex w-full flex-col gap-4",
                hasAbsolutePositionExplanation && "absolute bottom-5 w-[95%]",
                isMultiDragAndDrop && "absolute bottom-10 w-2/6",
              )}
            >
              {successMessage && !hasError && (
                <CorrectAnswerResultText text={successMessage} />
              )}

              {hasError && <IncorrectAnswerResultText message={errorMessage} />}
            </div>
          )}
        </div>
      </div>
      <NextAndPreviousButtons
        onCheckClick={onCheckClick}
        isCheckDisabled={isCheckDisabled}
        resetActivityState={resetActivityState}
      />
    </div>
  );
};
