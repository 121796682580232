import type { SVGProps } from "~/constants";

export const InfoIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z'
      stroke='#854D0E'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 5.3335V8.00016'
      stroke='#854D0E'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 10.6665H8.00667'
      stroke='#854D0E'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
