import React, { useState } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

import {
  EyeIcon,
  EyeOffIcon,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "~/shared";

interface PasswordInputFormItemProps<TFormValues extends FieldValues> {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  hasError?: boolean;
  label?: string;
  placeholder?: string;
}

const PasswordInputFormItem = <TFormValues extends FieldValues>({
  name,
  control,
  hasError,
  label,
  placeholder = "Enter your password",
}: PasswordInputFormItemProps<TFormValues>) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((prevState) => !prevState);

  return (
    <FormField
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          <FormLabel className='font-medium'>{label}</FormLabel>
          <FormControl>
            <div className='relative'>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder={placeholder}
                {...field}
                className={error ?? hasError ? "border-error-400" : ""}
              />
              <button
                type='button'
                onClick={toggleShowPassword}
                className='absolute inset-y-0 right-3 my-auto size-4 text-primary-500'
              >
                {showPassword ? <EyeIcon /> : <EyeOffIcon />}
              </button>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default PasswordInputFormItem;
