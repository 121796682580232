import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { BooksIcon, KidneyIcon, ListIcon } from "~/shared";
import { ProgressBar } from "~/shared/components/utils/ProgressBar";
import ProgressBarSkeleton from "~/shared/components/utils/ProgressBarSkeleton";
import { useGetModuleProgress } from "~/shared/hooks/queries/activities/useGetModuleProgress";
import { useUiStore } from "~/stores";
import type { Course } from "~/types/courses";
import { CourseDrawer } from "./CourseDrawer";
import { PathStepItem } from "./PathStepItem";

interface HeaderProps {
  activityPath: string[] | null;
  course: Course;
  activityLabel: string;
}

export const Header = ({
  activityPath,
  course,
  activityLabel,
}: HeaderProps) => {
  const { t } = useTranslation();

  const { moduleId } = useParams();

  const { data, isLoading } = useGetModuleProgress(moduleId);

  const { isCourseDrawerOpen, setIsCourseDrawerOpen } = useUiStore();

  const { completedActivities, totalActivities } = { ...data?.progress };

  const progressAvailable = completedActivities && totalActivities;

  const progress = progressAvailable
    ? Math.round((completedActivities / totalActivities) * 100)
    : 1;

  const totalCompletedActivities = progressAvailable
    ? `${completedActivities} / ${totalActivities} ${t("Activities")}`
    : "";

  const toggleDrawer = () => setIsCourseDrawerOpen(!isCourseDrawerOpen);
  return (
    <div>
      <div className='relative flex flex-col gap-8 overflow-hidden bg-gray-50 px-14 py-8'>
        <div className='z-30 flex flex-row items-center gap-2'>
          <BooksIcon />
          {activityPath?.map((pathCode, index) => (
            <PathStepItem
              key={pathCode}
              pathCode={pathCode}
              isLast={activityPath.length === index + 1}
              activityLabel={activityLabel}
            />
          ))}
        </div>
        <div className='z-10 flex flex-row items-center gap-6'>
          {isLoading ? (
            <ProgressBarSkeleton />
          ) : (
            <ProgressBar
              progressClassName='rounded-full'
              className='rounded-full'
              progress={progress}
            />
          )}
          <div className='flex min-w-fit flex-row items-center gap-2'>
            <p className='text-gray-600'>{totalCompletedActivities}</p>
            <button onClick={toggleDrawer}>
              <ListIcon className='text-primary-500' />
            </button>
          </div>
        </div>
        <KidneyIcon className='absolute -right-12 -top-16 max-h-[16.5rem]' />
      </div>
      <CourseDrawer course={course} />
    </div>
  );
};
