import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import { ROUTES } from "~/router";
import { ArrowIcon, Text } from "~/shared";
import { getActivityIcon } from "~/shared/utils/activities/getActivityIcon";
import { useUiStore } from "~/stores";
import type { Activity } from "~/types";

interface ActivityCardProps {
  chapterId: string;
  moduleId: string;
  activity?: Activity;
}

export const ActivityCard = ({
  chapterId,
  moduleId,
  activity,
}: ActivityCardProps) => {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { setIsCourseDrawerOpen } = useUiStore();

  if (!activity || !courseId) return null;

  const redirectPath = generatePath(ROUTES.activity, {
    courseId,
    chapterId,
    moduleId,
    type: activity.type,
    activityId: activity.id,
  });

  return (
    <Link
      to={redirectPath}
      onClick={() => setIsCourseDrawerOpen(false)}
      className='flex w-full flex-row items-center justify-between rounded-lg border border-primary-50 bg-neutral-white px-6 py-5'
    >
      <div className='flex flex-row items-center gap-2'>
        {getActivityIcon({ activityType: activity.type, size: "md" })}
        <div className='flex flex-col'>
          <Text className='text-xs uppercase text-gray-500'>
            {t(`activities.types.${activity.type}`)}
          </Text>
          <Text className='text-sm font-medium'>
            {t(`activities.${activity.label}`)}
          </Text>
        </div>
      </div>

      <ArrowIcon direction='right' className='text-primary-500' />
    </Link>
  );
};
