import type { SVGProps } from "~/constants";

export const RoundedCheckIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M7.99998 14.6667C11.682 14.6667 14.6666 11.682 14.6666 8C14.6666 4.318 11.682 1.33333 7.99998 1.33333C4.31798 1.33333 1.33331 4.318 1.33331 8C1.33331 11.682 4.31798 14.6667 7.99998 14.6667Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 8.00001L7.33333 9.33334L10 6.66667'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
