import { useParams } from "react-router-dom";

import type { SliderActivity } from "~/api/getSliderActivity";
import { useGetSliderActivityQuery } from "~/shared/hooks";
import LoaderSection from "../ui/LoaderSection/LoaderSection";
import { AmountSlider } from "./sliders/AmountSlider";
import { AmountSliderWithImage } from "./sliders/AmountSliderWithImage";
import { DoublePersonSlider } from "./sliders/DoublePersonSlider";
import { SliderOne } from "./sliders/SliderOne";
import { SliderTwo } from "./sliders/SliderTwo";

const SLIDER_TITLES = {
  one: "renalPhysiology.chapter3.module3.activity6.title",
  two: "renalPhysiology.chapter3.module3.activity7.title",
  doubleSliderOne: "renalPhysiology.chapter3.module4.activity6.title",
  doubleSliderTwo: "renalPhysiology.chapter3.module4.activity7.title",
  doubleSliderThree: "renalPhysiology.chapter3.module4.activity8.title",
  doubleSliderFour: "renalPhysiology.chapter3.module4.activity9.title",
  doubleSliderFive: "renalPhysiology.chapter3.module4.activity10.title",
  amountSliderWithImageOne: "renalPhysiology.chapter3.module5.activity13.title",
  amountSliderWithImageTwo: "renalPhysiology.chapter3.module5.activity14.title",
  amountSliderOne: "renalPhysiology.chapter3.module7.activity9.title",
  amountSliderTwo: "renalPhysiology.chapter3.module7.activity10.title",
  amountSliderThree: "renalPhysiology.chapter3.module7.activity11.title",
  amountSliderFour: "renalPhysiology.chapter3.module7.activity12.title",
  amountSliderFive: "renalPhysiology.chapter3.module7.activity13.title",
  amountSliderSix: "renalPhysiology.chapter3.module7.activity14.title",
};

const renderSlider = (activityData: SliderActivity) => {
  const SLIDER_IDS: Record<string, JSX.Element> = {
    [SLIDER_TITLES.one]: (
      <SliderOne activityData={activityData} key={SLIDER_TITLES.one} />
    ),
    [SLIDER_TITLES.two]: (
      <SliderTwo activityData={activityData} key={SLIDER_TITLES.two} />
    ),
    [SLIDER_TITLES.doubleSliderOne]: (
      <DoublePersonSlider
        activityData={activityData}
        key={SLIDER_TITLES.doubleSliderOne}
      />
    ),
    [SLIDER_TITLES.doubleSliderTwo]: (
      <DoublePersonSlider
        activityData={activityData}
        key={SLIDER_TITLES.doubleSliderTwo}
      />
    ),
    [SLIDER_TITLES.doubleSliderThree]: (
      <DoublePersonSlider
        activityData={activityData}
        key={SLIDER_TITLES.doubleSliderThree}
      />
    ),
    [SLIDER_TITLES.doubleSliderFour]: (
      <DoublePersonSlider
        activityData={activityData}
        key={SLIDER_TITLES.doubleSliderFour}
      />
    ),
    [SLIDER_TITLES.doubleSliderFive]: (
      <DoublePersonSlider
        activityData={activityData}
        key={SLIDER_TITLES.doubleSliderFive}
      />
    ),
    [SLIDER_TITLES.amountSliderWithImageOne]: (
      <AmountSliderWithImage
        activityData={activityData}
        key={SLIDER_TITLES.amountSliderWithImageOne}
      />
    ),
    [SLIDER_TITLES.amountSliderWithImageTwo]: (
      <AmountSliderWithImage
        activityData={activityData}
        key={SLIDER_TITLES.amountSliderWithImageTwo}
      />
    ),
    [SLIDER_TITLES.amountSliderOne]: (
      <AmountSlider
        activityData={activityData}
        key={SLIDER_TITLES.amountSliderOne}
      />
    ),
    [SLIDER_TITLES.amountSliderTwo]: (
      <AmountSlider
        activityData={activityData}
        key={SLIDER_TITLES.amountSliderTwo}
      />
    ),
    [SLIDER_TITLES.amountSliderThree]: (
      <AmountSlider
        activityData={activityData}
        key={SLIDER_TITLES.amountSliderThree}
      />
    ),
    [SLIDER_TITLES.amountSliderFour]: (
      <AmountSlider
        activityData={activityData}
        key={SLIDER_TITLES.amountSliderFour}
      />
    ),
    [SLIDER_TITLES.amountSliderFive]: (
      <AmountSlider
        activityData={activityData}
        key={SLIDER_TITLES.amountSliderFive}
      />
    ),
    [SLIDER_TITLES.amountSliderSix]: (
      <AmountSlider
        activityData={activityData}
        key={SLIDER_TITLES.amountSliderSix}
      />
    ),
  };

  return SLIDER_IDS[activityData.title];
};

export const SliderRenderer = () => {
  const { activityId } = useParams();
  const { data: activityData, isPending } =
    useGetSliderActivityQuery(activityId);

  if (isPending) return <LoaderSection />;

  if (activityData) {
    return renderSlider(activityData);
  }
  return null;
};
