import { create } from "zustand";
import { persist } from "zustand/middleware";

const tokenExpireTransformFactor = 1000;

export interface User {
  email: string;
  name: string;
}

export interface UserStoreState {
  user: User | null;
  setUser(user: User | null): void;
  token: string | null;
  setToken(token: string | null): void;
  tokenExpiresAt: Date | null;
  setTokenExpiresAt(tokenExpiresIn: number): void;
  isTokenExpired(): boolean;
}

export const useUserStore = create<UserStoreState>()(
  persist(
    (set, get) => ({
      user: null,
      token: null,
      tokenExpiresAt: null,
      setUser: (user: User | null) => {
        set(() => ({ user }));
      },
      setToken: (token: string | null) => {
        set(() => ({ token }));
      },
      setTokenExpiresAt: (tokenExpiresIn: number) => {
        const expirationDate = new Date(
          Date.now() + tokenExpiresIn * tokenExpireTransformFactor,
        );
        set(() => ({ tokenExpiresAt: expirationDate }));
      },
      isTokenExpired: () => {
        const { tokenExpiresAt } = get();
        return tokenExpiresAt ? tokenExpiresAt < new Date() : true;
      },
    }),
    {
      name: "feedbackUserStore",
    },
  ),
);
