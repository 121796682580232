import type { SVGProps } from "~/constants";

export const CheckCircleIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <g clipPath='url(#clip0_255_38382)'>
      <path
        d='M8.00004 14.6666C11.682 14.6666 14.6667 11.6819 14.6667 7.99992C14.6667 4.31792 11.682 1.33325 8.00004 1.33325C4.31804 1.33325 1.33337 4.31792 1.33337 7.99992C1.33337 11.6819 4.31804 14.6666 8.00004 14.6666Z'
        stroke='#5FA28D'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 8.00008L7.33333 9.33341L10 6.66675'
        stroke='#5FA28D'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_255_38382'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
