import type { SVGProps } from "~/constants";

export const RoundedXIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8C14.6666 4.3181 11.6818 1.33333 7.99992 1.33333C4.31802 1.33333 1.33325 4.3181 1.33325 8C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z'
      stroke='#991B1B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 6L6 10'
      stroke='#991B1B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 6L10 10'
      stroke='#991B1B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
