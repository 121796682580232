import { useQuery } from "@tanstack/react-query";

import { listCourses } from "~/api/courses/course";

export const useListCourses = () => {
  const { data, isLoading } = useQuery({
    queryFn: listCourses,
    queryKey: ["listCourses"],
  });

  return { data, isLoading };
};
