import type { SVGPropsWithFillColor } from "~/constants";

export const ThinWavesSVG = ({
  className,
  fillColor,
  ...props
}: SVGPropsWithFillColor) => (
  <g width='236' height='5' className={className} {...props}>
    <path
      d='M11.8 1.5C7.86667 -0.5 3.93333 -0.5 0 1.5V4.5H236V1.5C232.067 3.5 228.133 3.5 224.2 1.5C220.267 -0.5 216.333 -0.5 212.4 1.5C208.467 3.5 204.533 3.5 200.6 1.5C196.667 -0.5 192.733 -0.5 188.8 1.5C184.867 3.5 180.933 3.5 177 1.5C173.067 -0.5 169.133 -0.5 165.2 1.5C161.267 3.5 157.333 3.5 153.4 1.5C149.467 -0.5 145.533 -0.5 141.6 1.5C137.667 3.5 133.733 3.5 129.8 1.5C125.867 -0.5 121.933 -0.5 118 1.5C114.067 3.5 110.133 3.5 106.2 1.5C102.267 -0.5 98.3333 -0.5 94.4 1.5C90.4667 3.5 86.5333 3.5 82.6 1.5C78.6667 -0.5 74.7333 -0.5 70.8 1.5C66.8667 3.5 62.9333 3.5 59 1.5C55.0667 -0.5 51.1333 -0.5 47.2 1.5C43.2667 3.5 39.3333 3.5 35.4 1.5C31.4667 -0.5 27.5333 -0.5 23.6 1.5C19.6667 3.5 15.7333 3.5 11.8 1.5Z'
      fill={fillColor}
    />
  </g>
);
