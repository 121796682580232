import { t } from "i18next";
import type { Renderable } from "react-hot-toast";

import { tw } from "~/shared/utils";
import { ErrorIcon, Text } from "../..";

interface ErrorToastProps {
  message: Renderable;
}

export const ErrorToast = ({ message }: ErrorToastProps) => {
  return (
    <div
      className={tw(
        "flex gap-1 rounded-lg border border-error-800 bg-error-30 p-4 text-error-800 shadow-md",
      )}
    >
      <ErrorIcon />
      <div>
        <Text className='ml-2 font-semibold'>{`${t("ui.oops")}  👀`}</Text>
        <Text className='text-error-800'>{message}</Text>
      </div>
    </div>
  );
};
