import type { SVGProps } from "~/constants";

export const VideoIcon = ({ className, ...props }: SVGProps) => (
  <svg
    className={className}
    {...props}
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.11111 13.037C10.3839 13.037 13.037 10.3839 13.037 7.11111C13.037 3.83831 10.3839 1.18518 7.11111 1.18518C3.83831 1.18518 1.18518 3.83831 1.18518 7.11111C1.18518 10.3839 3.83831 13.037 7.11111 13.037Z'
      stroke='#BF771F'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.9259 4.74075L9.48146 7.11112L5.9259 9.48149V4.74075Z'
      stroke='#BF771F'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
