import React from "react";

import { tw } from "~/shared/utils";

interface ProgressBarProps {
  progress: number;
  className?: string;
  progressClassName?: string;
}
export const ProgressBar = ({
  progress,
  className,
  progressClassName,
}: ProgressBarProps) => {
  const MIN_DISPLAY_PROGRESS = 5;
  const displayProgress =
    progress < MIN_DISPLAY_PROGRESS ? MIN_DISPLAY_PROGRESS : progress;

  return (
    <div className={tw("h-2.5 w-full bg-success-200", className)}>
      <div
        className={tw(
          "h-2.5 rounded-r-full bg-success-300 transition-all duration-500",
          progressClassName,
        )}
        style={{ width: `${displayProgress}%` }}
      />
    </div>
  );
};
