import { useMutation } from "@tanstack/react-query";
import { t } from "i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { logout } from "~/api/auth";
import { ROUTES } from "~/router";
import { useUserStore } from "~/stores";

export const useLogoutMutation = () => {
  const navigate = useNavigate();
  const { setUser, setToken } = useUserStore.getState();

  const { mutate, isPending } = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      setUser(null);
      setToken(null);
      navigate(ROUTES.login);
    },
    onError: () => {
      toast.error(t("ui.errorMessage"));
    },
  });

  return { mutate, isPending };
};
