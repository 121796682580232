import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { z } from "zod";

import type { ForgotPasswordRequest } from "~/api/auth";
import { Button, Form } from "~/shared";
import { useForgotPasswordMutation } from "~/shared/hooks/queries/useForgotPasswordMutation";
import { forgotPasswordFormSchema } from "~/shared/schemas";
import Spinner from "../ui/Spinner/Spinner";
import BaseInputFormItem from "../utils/InputFormItem";

type ForgotPasswordFormValuesType = z.infer<typeof forgotPasswordFormSchema>;

interface ForgotPasswordFormProps {
  setEmail: (email: string) => void;
}

export const ForgotPasswordForm = ({ setEmail }: ForgotPasswordFormProps) => {
  const { t } = useTranslation();

  const form = useForm<ForgotPasswordFormValuesType>({
    resolver: zodResolver(forgotPasswordFormSchema),
  });

  const onSuccess = ({ email }: ForgotPasswordRequest) => {
    setEmail(email);
  };

  const {
    mutate: forgotPasswordMutation,
    isPending,
    error,
  } = useForgotPasswordMutation({ onSuccess });

  const onSubmit: SubmitHandler<ForgotPasswordFormValuesType> = ({ email }) => {
    forgotPasswordMutation({ email });
  };

  const { isDirty } = form.formState;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className='flex w-full flex-col gap-8'
      >
        <div className='flex flex-col gap-4'>
          <BaseInputFormItem
            control={form.control}
            name='email'
            label='Email'
            type='email'
            placeholder={t("login.email.placeholder")}
            hasError={!!error}
          />
        </div>
        <Button
          className='w-full font-semibold'
          type='submit'
          disabled={!isDirty}
        >
          {isPending ? <Spinner /> : t("forgotPassword.sendLink")}
        </Button>
      </form>
    </Form>
  );
};
