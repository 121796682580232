export const SLIDER_BASE_HEIGHT = 450;
export const SLIDER_MULTIPLIER = 4.4;
export const SINGLE_SLIDER_MULTIPLIER = 4.5;
export const ICON_OFFSET = -15;
export const ICON_POSITION_MULTIPLIER = 3.9;
export const SLIDER_WIDTH = 200;
export const SLIDER_HEIGHT = 400;
export const INPUT_OFFSET_LEFT = 199;
export const WATER_SLIDER_POSITION = "0%";
export const SALT_SLIDER_POSITION = "-50%";
export const ICON_POSITION_BOTTOM = "-400px";
export const SALT_FILL_COLOR = "#EDEEF1";
export const WATER_FILL_COLOR = "#1E5CAF";
export const LIGHT_WATER_FILL_COLOR = "#DBEAFE";
export const BASE_FILL_FOR_HALF_BODY_SLIDERS = 45;
export const BASE_VALUE = 50;
export const RECTANGLE_BASE_X = 290;
export const RECTANGLE_BASE_Y = 900;
export const WAVES_BASE_X = 190;
export const WAVES_BASE_Y = 896;

export const COMMON_SLIDER_STYLES: React.CSSProperties = {
  position: "relative",
  width: `${SLIDER_WIDTH}px`,
  height: `${SLIDER_HEIGHT}px`,
};

export const calculateIconPosition = (height: number): React.CSSProperties => ({
  bottom: `${ICON_OFFSET + height * ICON_POSITION_MULTIPLIER}px`,
});

export const RECTANGLE_GROW_BOTTOM_UP_TRANSFORM = `translate(${RECTANGLE_BASE_X}, ${RECTANGLE_BASE_Y}) rotate(-180)`;
