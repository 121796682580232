import React from "react";
import { tv } from "tailwind-variants";
import type { VariantProps } from "tailwind-variants";

const headingVariants = tv({
  base: "text-center text-neutral-black",
  variants: {
    variant: {
      h1: "text-4xl leading-10",
      h2: "text-3xl font-semibold leading-9",
      h3: "text-left text-2xl font-semibold leading-8",
    },
  },
  defaultVariants: {
    variant: "h1",
  },
});

export interface HeadingProps extends VariantProps<typeof headingVariants> {
  className?: string;
  children: React.ReactNode;
}

const Heading: React.FC<HeadingProps> = ({
  className = "",
  children,
  variant = "h1",
  ...props
}) => {
  const Component = variant;

  return (
    <Component className={headingVariants({ variant, className })} {...props}>
      {children}
    </Component>
  );
};

export { Heading, headingVariants };
