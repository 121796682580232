import { tw } from "~/shared/utils";

interface SliderIconProps {
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  positionStyle: React.CSSProperties;
  className: string;
}

export const SliderIcon: React.FC<SliderIconProps> = ({
  IconComponent,
  positionStyle,
  className,
}) => (
  <IconComponent
    className={tw("absolute h-10 w-10", className)}
    style={positionStyle}
  />
);
