import { useState } from "react";
import type { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { useTranslation } from "react-i18next";

import type { DragAndDropActivityResponse } from "~/api/getDragActivity";
import { Button, EmptyPictureIcon, FullImageSVG, tw } from "~/shared";
import { Draggable } from "../Draggable";
import { DraggableOverlayContent } from "../DragOverlayContent";
import { ImageModal } from "../ImageModal";
import { MULTI_DRAG_BOX_CLASS_NAME } from "./MultiDragAndDropConfigs";
import { MultiDropzoneSlot } from "./MultiDropzoneSlot";

interface DragAndDropAreaPropsType {
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  activityData: DragAndDropActivityResponse["data"];
  findDraggingOption: () => string;
  draggableIdsWithError: string[];
  dropzoneIdsWithError: string[];
  activeId: UniqueIdentifier | null;
  draggablePositions: Record<string, UniqueIdentifier | null>;
}

export const MultiDragAndDropArea = ({
  handleDragEnd,
  handleDragStart,
  activityData,
  findDraggingOption,
  draggableIdsWithError,
  dropzoneIdsWithError,
  activeId,
  draggablePositions,
}: DragAndDropAreaPropsType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const findDraggableLabelToRender = (draggableIdToRender: number) => {
    const draggableLabelToRender = activityData.options.find(
      (option) => option.id === draggableIdToRender,
    );
    return draggableLabelToRender ? t(draggableLabelToRender.value) : "";
  };
  return (
    <div className='w-full'>
      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t("renalPhysiology.chapter3.module8.imageModalTitle")}
        imageSVG={<FullImageSVG />}
      />

      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <div className={tw("flex w-full gap-10")}>
          <div className='flex w-[35%] flex-col gap-6'>
            <Button
              variant='underline'
              className='flex w-1/4 justify-start gap-1 text-gray-500'
              onClick={() => setIsModalOpen(true)}
            >
              <EmptyPictureIcon />
              <p>{t("renalPhysiology.chapter3.module8.buttonModalLabel")}</p>
            </Button>
            <div className='flex flex-col justify-center gap-2'>
              {activityData?.options.map(
                (draggable) =>
                  !draggablePositions[draggable.id] && (
                    <Draggable
                      key={draggable.id}
                      draggableId={draggable.id}
                      option={t(draggable.value)}
                      className={MULTI_DRAG_BOX_CLASS_NAME}
                    />
                  ),
              )}
            </div>
          </div>
          <div className='flex w-2/3 flex-wrap gap-2'>
            {activityData?.slots.map((slot) => {
              return (
                <MultiDropzoneSlot
                  key={slot.id}
                  slot={slot}
                  draggablePositions={draggablePositions}
                  boxClassName={MULTI_DRAG_BOX_CLASS_NAME}
                  findDraggableLabelToRender={findDraggableLabelToRender}
                  draggableIdsWithError={draggableIdsWithError}
                  dropzoneIdsWithError={dropzoneIdsWithError}
                />
              );
            })}
          </div>
        </div>
        <DragOverlay
          dropAnimation={{
            duration: 500,
            easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
          }}
        >
          {activeId ? (
            <DraggableOverlayContent
              className={tw(MULTI_DRAG_BOX_CLASS_NAME, "w-full")}
              option={findDraggingOption()}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};
