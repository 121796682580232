import type { SVGProps } from "~/constants";

export const PaperIcon = ({ className }: SVGProps) => (
  <svg
    width='12'
    height='14'
    viewBox='0 0 12 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M7.45801 1.1665H2.49967C2.19026 1.1665 1.89351 1.28942 1.67472 1.50821C1.45592 1.72701 1.33301 2.02375 1.33301 2.33317V11.6665C1.33301 11.9759 1.45592 12.2727 1.67472 12.4915C1.89351 12.7103 2.19026 12.8332 2.49967 12.8332H9.49968C9.80909 12.8332 10.1058 12.7103 10.3246 12.4915C10.5434 12.2727 10.6663 11.9759 10.6663 11.6665V4.37484L7.45801 1.1665Z'
      stroke='#1F2937'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.16699 1.1665V4.6665H10.667'
      stroke='#1F2937'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
