import type { ReactNode } from "react";
import React from "react";

import type { SliderType } from "~/api/getSliderActivity";
import { Slider } from "~/shared/components/ui/Slider/Slider";

interface AmountSliderCardProps {
  value: number;
  setValue: (value: number) => void;
  slider: SliderType;
  leftData: ReactNode;
  rightData: ReactNode;
}

export const AmountSliderInfoCard = ({
  slider,
  value,
  setValue,
  leftData,
  rightData,
}: AmountSliderCardProps) => {
  return (
    <div
      key={slider.id}
      className='relative flex w-full flex-row items-center justify-start gap-4 overflow-hidden rounded-xl border border-primary-30 bg-neutral-gray p-4'
    >
      {leftData}
      <Slider
        min={Number(slider.minimum)}
        max={Number(slider.maximum)}
        value={value}
        onChange={(e) => setValue(Number(e.target.value))}
      />
      {rightData}
    </div>
  );
};
