import type { UniqueIdentifier } from "@dnd-kit/core";
import { useDraggable } from "@dnd-kit/core";

export const Draggable = ({
  option,
  draggableId,
  className,
  dragIcons,
}: {
  option: string;
  draggableId: UniqueIdentifier;
  hasError?: boolean;
  className?: string;
  dragType?: number;
  dragIcons?: Record<string, JSX.Element>;
}) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: draggableId,
  });
  const style = {
    opacity: isDragging ? 0 : 1,
  };

  return (
    <button
      key={draggableId}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className={className}
    >
      {dragIcons?.[option] ?? <p>{option}</p>}
    </button>
  );
};
