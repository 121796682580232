export const DraggableOverlayContent = ({
  option,
  className,
  dragIcons,
}: {
  option: string;
  hasError?: boolean;
  className?: string;
  dragIcons?: Record<string, JSX.Element>;
}) => {
  return (
    <button className={className}>
      {dragIcons?.[option] ?? <p>{option}</p>}
    </button>
  );
};
