import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { CoursePicture } from "~/assets";
import type { CourseProgress } from "~/types";
import { SaveIcon } from "../icons";
import { ProgressBar } from "../utils/ProgressBar";

interface CourseCardProps {
  id: string;
  title: string;
  description: string;
  progress: CourseProgress;
}
export const CourseCard = ({
  id,
  title,
  description,
  progress,
}: CourseCardProps) => {
  const { t } = useTranslation();
  const { totalModules, completedModules } = progress;
  return (
    <Link
      to={`/course/${id}`}
      className='flex h-fit w-course-card flex-col overflow-hidden rounded-xl border border-neutral-cream shadow-sm'
    >
      <div className='h-52 overflow-hidden'>
        <img className='w-full' src={CoursePicture} alt='course' />
      </div>
      <ProgressBar
        progress={Math.round((completedModules / totalModules) * 100)}
      />
      <div className='flex w-full flex-col gap-4 p-5 pt-2.5'>
        <div className='flex flex-col gap-1'>
          <p className='text-gray-600'>{t("uppercaseCourse")}</p>
          <h1 className='font-tobias text-2xl text-neutral-black'>
            {t(`courses.${title}`)}
          </h1>
        </div>
        <p className='whitespace-pre-wrap leading-5 text-neutral-black'>
          {t(`courses.${description}`)}
        </p>
        <div className='h-0.5 w-full bg-primary-50' />
        <div className='flex flex-row items-center gap-2'>
          <SaveIcon className='text-primary' />
          <p>
            {totalModules} {t("ui.modules")}
          </p>
        </div>
      </div>
    </Link>
  );
};
