import type { ReactNode } from "react";
import { t } from "i18next";

import {
  AmountSliderIcon1,
  AmountSliderIcon2,
  ArrowIcon,
  Text,
  VasoconstrictIcon,
  VasodilateIcon,
} from "~/shared/components";

interface AmountSliderCardProps {
  icon: ReactNode;
  leftData: ReactNode;
  rightData: ReactNode;
}

export const getSliderDataByTitle: Record<string, AmountSliderCardProps> = {
  "renalPhysiology.chapter3.module5.activity13.slider1.title": {
    icon: <AmountSliderIcon1 className='h-64 w-full 1280:h-80' />,
    leftData: (
      <div className='flex flex-row items-center gap-2'>
        <VasoconstrictIcon />
        <Text>{t("ui.vasoconstrict")}</Text>
      </div>
    ),
    rightData: (
      <div className='flex flex-row items-center gap-2'>
        <VasodilateIcon />
        <Text>{t("ui.vasodilate")}</Text>
      </div>
    ),
  },
  "renalPhysiology.chapter3.module5.activity14.slider1.title": {
    icon: <AmountSliderIcon2 className='h-64 w-full 1280:h-96' />,
    leftData: (
      <div className='flex flex-row items-center gap-2'>
        <ArrowIcon direction='down' className='text-primary' />
        <Text>{t("ui.decrease")}</Text>
      </div>
    ),
    rightData: (
      <div className='flex flex-row items-center gap-2'>
        <ArrowIcon direction='top' className='text-primary' />
        <Text>{t("ui.increase")}</Text>
      </div>
    ),
  },
};
