import { useTranslation } from "react-i18next";

import { ChevronIcon, DIRECTIONS, tw } from "~/shared";

interface ExpandableCardProps {
  isExpanded: boolean;
  onToggleExpanded: () => void;
  order: number;
  title: string;
}

export const ExpandableCard = ({
  isExpanded,
  onToggleExpanded,
  order,
  title,
}: ExpandableCardProps) => {
  const { t } = useTranslation();
  return (
    <button
      className={tw(
        "flex h-24 w-full flex-row items-center gap-4 rounded-xl border border-primary-100 bg-primary-30 p-5 text-left",
        isExpanded && "rounded-b-none",
      )}
      onClick={onToggleExpanded}
    >
      <ChevronIcon
        direction={isExpanded ? DIRECTIONS.top : DIRECTIONS.down}
        className='size-3 text-primary'
      />
      <div className='flex flex-col gap-1'>
        <p className='text-sm text-gray-600'>
          {t("uppercaseModule")} {order}
        </p>
        <p className='text-xl text-neutral-black'>{t(`modules.${title}`)}</p>
      </div>
    </button>
  );
};
