import { useCallback, useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";

import { ROUTES } from "~/router";
import { getNextActivity } from "../utils/activities/getNextActivity";
import { getPreviousActivity } from "../utils/activities/getPreviousActivity";
import { useGetCourseCached } from "./queries/courses/useGetCourseCached";

export const PREVIOUS_VALUE = {
  previous: "previous",
  previousModule: "previousModule",
  previousChapter: "previousChapter",
} as const;

export const NEXT_VALUE = {
  next: "next",
  nextModule: "nextModule",
  nextChapter: "nextChapter",
  backToCourse: "backToCourse",
} as const;

export type PreviousValue = keyof typeof PREVIOUS_VALUE;
export type NextValue = keyof typeof NEXT_VALUE;

export const useActivitiesNavigation = () => {
  const [nextPath, setNextPath] = useState("");
  const [previousPath, setPreviousPath] = useState("");

  const [nextLabel, setNextLabel] = useState<NextValue>(NEXT_VALUE.next);
  const [previousLabel, setPreviousLabel] = useState<PreviousValue>();

  const {
    activityId: currentActivityId,
    chapterId: currentChapterId,
    moduleId: currentModuleId,
    courseId,
    type,
  } = useParams();

  const {
    cachedCourseQuery: { data: courseData },
  } = useGetCourseCached(courseId);

  const { data: course } = { ...courseData };

  const getNext = useCallback(() => {
    const next = getNextActivity(
      course,
      currentChapterId,
      currentModuleId,
      currentActivityId,
      type,
    );

    return next;
  }, [course, currentActivityId, currentChapterId, currentModuleId, type]);

  const getPrevious = useCallback(() => {
    const previous = getPreviousActivity(
      course,
      currentChapterId,
      currentModuleId,
      currentActivityId,
      type,
    );

    return previous;
  }, [course, currentActivityId, currentChapterId, currentModuleId, type]);

  const getNextPathAndLabel = useCallback(() => {
    const next = getNext();

    if (!next) {
      setNextLabel(NEXT_VALUE.backToCourse);
      return;
    }

    const { chapterId, moduleId, activityId, activityType } = { ...next };

    if (!activityId) {
      setNextPath(
        generatePath(ROUTES.courseDetails, { courseId: courseId ?? "" }),
      );
      setNextLabel(NEXT_VALUE.backToCourse);
      return;
    }

    const redirectPath = generatePath(ROUTES.activity, {
      chapterId,
      moduleId,
      activityId,
      courseId: courseId ?? "",
      type: activityType,
    });
    setNextPath(redirectPath);

    if (currentChapterId && chapterId > currentChapterId) {
      setNextLabel(NEXT_VALUE.nextChapter);
      return;
    }

    if (currentModuleId && moduleId > currentModuleId) {
      setNextLabel(NEXT_VALUE.nextModule);
      return;
    }

    setNextLabel(NEXT_VALUE.next);
  }, [courseId, currentChapterId, currentModuleId, getNext]);

  const getPreviousPathAndLabel = useCallback(() => {
    const previous = getPrevious();

    if (!previous) return;

    const { chapterId, moduleId, activityId, activityType } = { ...previous };

    if (!activityId) return;

    const redirectPath = generatePath(ROUTES.activity, {
      chapterId,
      moduleId,
      activityId,
      courseId: courseId ?? "",
      type: activityType,
    });
    setPreviousPath(redirectPath);

    if (currentChapterId && chapterId < currentChapterId) {
      setPreviousLabel(PREVIOUS_VALUE.previousChapter);
      return;
    }

    if (currentModuleId && moduleId < currentModuleId) {
      setPreviousLabel(PREVIOUS_VALUE.previousModule);
      return;
    }

    setPreviousLabel(PREVIOUS_VALUE.previous);
  }, [courseId, currentChapterId, currentModuleId, getPrevious]);

  useEffect(() => {
    getNextPathAndLabel();
    getPreviousPathAndLabel();
  }, [getNextPathAndLabel, getPreviousPathAndLabel]);

  return { nextPath, previousPath, nextLabel, previousLabel };
};
