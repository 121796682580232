import type { Course, CourseBasicData } from "~/types";
import type { ServiceResponse } from "../axios";
import { api, getAuthHeaders } from "../axios";

export const getCourse = async (id: string) => {
  const res = await api.get<ServiceResponse<Course>>(`/courses/${id}`);
  return res.data;
};

export const listCourses = async () => {
  const res = await api.get<ServiceResponse<CourseBasicData[]>>("/courses", {
    headers: getAuthHeaders(),
  });
  return res.data.data;
};
