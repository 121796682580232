import { t } from "i18next";
import { z } from "zod";

import { GRADUATION_YEARS } from "../constants/signUp";

const loginFormSchema = z.object({
  email: z
    .string()
    .email(t("validation.email.invalid"))
    .transform((email) => email.toLowerCase()),
  password: z.string().min(1, t("validation.password.required")),
});

const passwordSchema = z
  .string()
  .min(8, t("validation.password.minLength"))
  .regex(/[a-z]/, t("validation.password.lowercase"))
  .regex(/[A-Z]/, t("validation.password.uppercase"))
  .regex(/\d/, t("validation.password.number"))
  .regex(/[^A-Za-z0-9]/, t("validation.password.specialCharacter"));

const signUpFormSchema = z
  .object({
    gradYear: z.enum(GRADUATION_YEARS, {
      errorMap: () => ({ message: t("validation.gradYear.required") }),
    }),
    password: passwordSchema,
    passwordConfirm: z.string(),
  })
  .superRefine(({ passwordConfirm, password }, context) => {
    if (password !== passwordConfirm) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("validation.password.mismatch"),
        path: ["passwordConfirm"],
      });
    }
  });

const resetPasswordFormSchema = z
  .object({
    password: passwordSchema,
    passwordConfirm: z.string(),
  })
  .superRefine(({ passwordConfirm, password }, context) => {
    if (password !== passwordConfirm) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("validation.password.mismatch"),
        path: ["passwordConfirm"],
      });
    }
  });

const forgotPasswordFormSchema = z.object({
  email: z
    .string()
    .email(t("validation.email.invalid"))
    .transform((email) => email.toLowerCase()),
});
export {
  loginFormSchema,
  signUpFormSchema,
  forgotPasswordFormSchema,
  resetPasswordFormSchema,
};
