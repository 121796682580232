import type { SVGProps } from "~/constants";

export const BookIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='16'
    height='14'
    viewBox='0 0 16 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M1.3335 1H5.3335C6.04074 1 6.71902 1.28095 7.21911 1.78105C7.71921 2.28115 8.00016 2.95942 8.00016 3.66667V13C8.00016 12.4696 7.78945 11.9609 7.41438 11.5858C7.0393 11.2107 6.5306 11 6.00016 11H1.3335V1Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6667 1H10.6667C9.95942 1 9.28115 1.28095 8.78105 1.78105C8.28095 2.28115 8 2.95942 8 3.66667V13C8 12.4696 8.21071 11.9609 8.58579 11.5858C8.96086 11.2107 9.46957 11 10 11H14.6667V1Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
