import { useEffect, useState } from "react";

import { Button } from "../Button";

interface TimerProps {
  onComplete: () => void;
  initialTime?: number;
}

const CountdownTimer = ({ onComplete, initialTime = 120 }: TimerProps) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    if (timeLeft === 0) {
      onComplete();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  return (
    <Button disabled className='w-full font-semibold'>
      Wait {formatTime(timeLeft)}
    </Button>
  );
};

export default CountdownTimer;
