import { MODULE_DESCRIPTIONS } from "~/types";

export const moduleDescriptionPoints = {
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter1.module1.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    { text: "thirdPoint" },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter1.module2.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    { text: "thirdPoint" },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter3.module1.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    { text: "thirdPoint" },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter3.module2.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    { text: "thirdPoint" },
    { text: "fourthPoint" },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter3.module3.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    {
      text: "thirdPoint",
      subPoints: ["firstSubPoint", "secondSubPoint"],
    },
    { text: "fourthPoint" },
    { text: "fifthPoint" },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter3.module4.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    {
      text: "thirdPoint",
      subPoints: [
        "firstSubPoint",
        "secondSubPoint",
        "thirdSubPoint",
        "fourthSubPoint",
        "fifthSubPoint",
        "sixthSubPoint",
      ],
    },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter3.module5.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    { text: "thirdPoint" },
    { text: "fourthPoint" },
    { text: "fifthPoint" },
    { text: "sixthPoint" },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter3.module6.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    { text: "thirdPoint" },
    { text: "fourthPoint" },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter3.module7.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    { text: "thirdPoint" },
    { text: "fourthPoint" },
    { text: "fifthPoint" },
    { text: "sixthPoint" },
  ],
  [MODULE_DESCRIPTIONS["renalPhysiology.chapter3.module8.description"]]: [
    { text: "firstPoint" },
    { text: "secondPoint" },
    { text: "thirdPoint" },
    { text: "fourthPoint" },
    { text: "fifthPoint" },
    { text: "sixthPoint" },
  ],
};
