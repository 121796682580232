import React from "react";
import { useParams } from "react-router-dom";

import { tw, useGetDragActivityQuery } from "~/shared";
import { ActivityRenderer } from "~/shared/components/Activities/ActivityRenderer";
import { MULTI_DRAG_TITLE } from "~/shared/components/Activities/dragAndDrop";
import { Header } from "~/shared/components/Activities/ui/ActivityHeader/Header";
import { HeaderSkeleton } from "~/shared/components/Activities/ui/ActivityHeader/HeaderSkeleton";
import { useGetCourseCached } from "~/shared/hooks/queries/courses/useGetCourseCached";
import { getActivityPath } from "~/shared/utils/activities/getActivityPath";

export const Activity = () => {
  const { courseId, chapterId, moduleId, activityId, type } = useParams();

  const {
    cachedCourseQuery: { data, isLoading },
  } = useGetCourseCached(courseId);

  const { data: course } = { ...data };
  const { data: activityData } = useGetDragActivityQuery(
    activityId ?? "",
    type,
  );

  const activity = getActivityPath(
    course,
    chapterId,
    moduleId,
    activityId,
    type,
  );
  const isMultiDragAndDrop = activityData?.title === MULTI_DRAG_TITLE;
  return (
    <div
      className={tw(
        "flex flex-col",
        isMultiDragAndDrop ? "min-h-screen" : "h-screen",
      )}
    >
      {!isLoading && course ? (
        <Header
          activityLabel={activity?.activityLabel ?? ""}
          activityPath={activity?.activityPath ?? []}
          course={course}
        />
      ) : (
        <HeaderSkeleton />
      )}
      <ActivityRenderer />
    </div>
  );
};
