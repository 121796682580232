import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { SliderActivity, SliderType } from "~/api/getSliderActivity";
import { ActivityLayout } from "~/layout/ActivityLayout";
import { ClipboardListIcon, Text } from "~/shared";
import { checkAmountSliderIsCorrect } from "~/shared/utils/activities/checkAmountSliderIsCorrect";
import { getRandomErrorMessage } from "~/shared/utils/activities/getRandomErrorMessage";
import { BASE_VALUE } from "../constants";
import { AmountSliderCard } from "./AmountSliderCard";

type SliderValueKeys =
  | "firstSlider"
  | "secondSlider"
  | "thirdSlider"
  | "fourthSlider";
interface AmountSliderProps {
  activityData: SliderActivity;
}
export const AmountSlider = ({ activityData }: AmountSliderProps) => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState({
    success: false,
    error: false,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [sliderValues, setSliderValues] = useState({
    firstSlider: BASE_VALUE,
    secondSlider: BASE_VALUE,
    thirdSlider: BASE_VALUE,
    fourthSlider: BASE_VALUE,
  });

  const { sliders, explanation, description, title } = activityData;

  const slidersData: { name: SliderValueKeys; slider: SliderType }[] = [
    { name: "firstSlider", slider: sliders[0] },
    { name: "secondSlider", slider: sliders[1] },
    { name: "thirdSlider", slider: sliders[2] },
    { name: "fourthSlider", slider: sliders[3] },
  ];

  const validateAnswer = () => {
    const isCorrect = slidersData.every(({ name, slider }) =>
      checkAmountSliderIsCorrect(
        sliderValues[name],
        slider.answer_minimum,
        slider.answer_maximum,
      ),
    );
    if (!isCorrect) {
      setErrorMessage(getRandomErrorMessage());
    }

    setMessages({ success: isCorrect, error: !isCorrect });
  };

  const handleSliderChange = (name: SliderValueKeys, value: number) => {
    setSliderValues((prev) => ({ ...prev, [name]: value }));
  };

  const resetActivityState = () => {
    setSliderValues({
      firstSlider: BASE_VALUE,
      secondSlider: BASE_VALUE,
      thirdSlider: BASE_VALUE,
      fourthSlider: BASE_VALUE,
    });
    setMessages({ success: false, error: false });
  };

  return (
    <ActivityLayout
      title={t(`activities.${title}`)}
      successMessage={messages.success ? t(`activities.${explanation}`) : ""}
      errorMessage={errorMessage}
      hasError={messages.error}
      onCheckClick={validateAnswer}
      containerClassName='justify-start'
      resetActivityState={resetActivityState}
    >
      <div className='flex size-full justify-center'>
        <div className='flex h-full w-4/5 max-w-[58rem] flex-col gap-12'>
          <div className='flex w-full flex-row items-center gap-2 overflow-hidden rounded-lg bg-primary-50 p-4'>
            <ClipboardListIcon className='size-5 min-w-fit' />
            <Text className='leading-6'>{t(`activities.${description}`)}</Text>
          </div>
          <div className='flex w-full flex-col gap-4'>
            {slidersData.map(({ name, slider }, index) => (
              <AmountSliderCard
                key={name}
                setValue={(value) => handleSliderChange(name, value)}
                slider={slider}
                value={sliderValues[name]}
                position={index}
              />
            ))}
          </div>
        </div>
      </div>
    </ActivityLayout>
  );
};
