import { useParams } from "react-router-dom";

import { useGetDragActivityQuery } from "~/shared/hooks";
import LoaderSection from "../ui/LoaderSection/LoaderSection";
import { DragAndDrop, MULTI_DRAG_TITLE } from "./dragAndDrop";
import { MultiDragAndDrop } from "./dragAndDrop/multiDragAndDrop";

export const DragAndDropRenderer = () => {
  const { activityId } = useParams();
  const { data: activityData, isPending } = useGetDragActivityQuery(
    activityId ?? "",
  );

  if (isPending) return <LoaderSection />;

  if (!activityData) {
    return null;
  }

  const isMultiDragAndDrop = activityData.title === MULTI_DRAG_TITLE;
  if (isMultiDragAndDrop) {
    return <MultiDragAndDrop activityData={activityData} />;
  }
  return <DragAndDrop activityData={activityData} />;
};
