import type { SVGProps } from "~/constants";

export const DashboardIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M5 1H1.66667C1.29848 1 1 1.29848 1 1.66667V6.33333C1 6.70152 1.29848 7 1.66667 7H5C5.36819 7 5.66667 6.70152 5.66667 6.33333V1.66667C5.66667 1.29848 5.36819 1 5 1Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3335 1H9.00016C8.63197 1 8.3335 1.29848 8.3335 1.66667V3.66667C8.3335 4.03486 8.63197 4.33333 9.00016 4.33333H12.3335C12.7017 4.33333 13.0002 4.03486 13.0002 3.66667V1.66667C13.0002 1.29848 12.7017 1 12.3335 1Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3335 7H9.00016C8.63197 7 8.3335 7.29848 8.3335 7.66667V12.3333C8.3335 12.7015 8.63197 13 9.00016 13H12.3335C12.7017 13 13.0002 12.7015 13.0002 12.3333V7.66667C13.0002 7.29848 12.7017 7 12.3335 7Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5 9.66667H1.66667C1.29848 9.66667 1 9.96515 1 10.3333V12.3333C1 12.7015 1.29848 13 1.66667 13H5C5.36819 13 5.66667 12.7015 5.66667 12.3333V10.3333C5.66667 9.96515 5.36819 9.66667 5 9.66667Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
