import React from "react";
import { useTranslation } from "react-i18next";

import { DoctorHomePage } from "~/assets";
import { PaperIcon, Text } from "..";

const Hyperlink = ({ text, href = "#" }: { text: string; href?: string }) => (
  <div className='flex flex-row items-center gap-2'>
    <PaperIcon />
    <a
      href={href}
      target='_blank'
      className='font-semibold text-neutral-black underline'
      rel='noreferrer'
    >
      {text}
    </a>
  </div>
);

export const AboutUsSection = () => {
  const { t } = useTranslation();
  return (
    <div className='relative flex w-about-us max-w-about-us flex-col overflow-hidden bg-neutral-white'>
      <div className='z-50 flex h-full flex-col justify-between gap-8 p-10 pb-14'>
        <div className='flex flex-col gap-8'>
          <h1 className='font-tobias text-3xl'>{t("about.title")}</h1>
          <p className='whitespace-pre-wrap text-gray-600'>
            {t("about.description")}
          </p>
        </div>
        <div className='flex flex-col gap-8'>
          <div className='flex flex-col gap-4'>
            <Text className='font-tobias text-gray-700'>
              {t("hyperlink.help_measure_success")}
            </Text>
            <Hyperlink text={t("hyperlink.pre_course")} />
            <Hyperlink text={t("hyperlink.post_course")} />
          </div>
          <div className='flex flex-col gap-4'>
            <Text className='font-tobias text-gray-700'>
              {t("hyperlink.tell_us_what_you_think")}
            </Text>
            <Hyperlink text={t("hyperlink.post_course_survey")} />
          </div>
        </div>
      </div>
      <img className='absolute bottom-0' src={DoctorHomePage} alt='' />
    </div>
  );
};
