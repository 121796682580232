import React from "react";
import { useTranslation } from "react-i18next";

import { FormControl, FormItem, FormLabel, RadioGroupItem, tw } from "~/shared";
import type { ChoiceActivityOption } from "~/types/activities";

interface ChoiceOptionProps {
  option: ChoiceActivityOption;
  isSelected?: boolean;
}

const ChoiceOption = ({ option, isSelected }: ChoiceOptionProps) => {
  const { t } = useTranslation();
  const { id, value, isCorrect } = option;
  const selectedOptionCorrect = isSelected && isCorrect;
  const selectedOptionIncorrect = isSelected && !isCorrect;

  return (
    <FormItem className='flex items-center rounded-lg border border-gray-100 bg-white'>
      <label className='flex h-full w-full cursor-pointer flex-row items-center gap-3 px-4 py-3.5'>
        <FormControl className='min-w-fit'>
          <RadioGroupItem
            className={tw(
              "border border-primary-300 text-primary-300",
              selectedOptionCorrect && "border-success-500 text-success-500",
              selectedOptionIncorrect && "border-error-800 text-error-800",
            )}
            value={id}
          />
        </FormControl>
        <FormLabel className='cursor-pointer text-lg font-normal first-letter:capitalize'>
          {t(`activities.${value}`)}
        </FormLabel>
      </label>
    </FormItem>
  );
};

export default ChoiceOption;
