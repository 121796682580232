import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { SliderActivity } from "~/api/getSliderActivity";
import { ActivityLayout } from "~/layout/ActivityLayout";
import { checkAmountSliderIsCorrect } from "~/shared/utils/activities/checkAmountSliderIsCorrect";
import { getRandomErrorMessage } from "~/shared/utils/activities/getRandomErrorMessage";
import { getSliderDataByTitle } from "~/shared/utils/activities/getSliderDataByTitle";
import { BASE_VALUE } from "../constants";
import { AmountSliderInfoCard } from "./AmountSliderInfoCard";

interface AmountSliderWithImageProps {
  activityData: SliderActivity;
}

export const AmountSliderWithImage = ({
  activityData,
}: AmountSliderWithImageProps) => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState({
    success: false,
    error: false,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [sliderValue, setSliderValue] = useState(BASE_VALUE);

  const { sliders, explanation, title } = activityData;

  const slider = sliders[0];
  const sliderData = getSliderDataByTitle[slider.title];

  const validateAnswer = () => {
    const isCorrect = checkAmountSliderIsCorrect(
      sliderValue,
      slider.answer_minimum,
      slider.answer_maximum,
    );

    if (!isCorrect) {
      setErrorMessage(getRandomErrorMessage());
    }

    setMessages({ success: isCorrect, error: !isCorrect });
  };
  const resetActivityState = () => {
    setSliderValue(BASE_VALUE);
    setMessages({ success: false, error: false });
  };

  return (
    <ActivityLayout
      title={t(`activities.${title}`)}
      successMessage={messages.success ? t(`activities.${explanation}`) : ""}
      hasError={messages.error}
      errorMessage={errorMessage}
      onCheckClick={validateAnswer}
      containerClassName='justify-start'
      hasAbsolutePositionExplanation
      resetActivityState={resetActivityState}
    >
      <div className='flex size-full justify-center'>
        <div className='flex h-full w-4/5 max-w-[58rem] flex-col gap-12'>
          <div className='flex w-full flex-col items-center gap-6'>
            <AmountSliderInfoCard
              setValue={(e: number) => setSliderValue(e)}
              slider={slider}
              value={sliderValue}
              leftData={sliderData?.leftData}
              rightData={sliderData?.rightData}
            />
            {sliderData?.icon}
          </div>
        </div>
      </div>
    </ActivityLayout>
  );
};
