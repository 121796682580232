import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { BooksIcon } from "~/shared";
import { ChapterCard } from "~/shared/components/courses/chapters/ChapterCard";
import { CourseDetailsSkeleton } from "~/shared/components/courses/CourseDetailsSkeleton";
import { useGetCourse } from "~/shared/hooks/queries/courses/useGetCourse";

export const CourseDetails = () => {
  const { courseId } = useParams();
  const { t } = useTranslation();
  const {
    courseQuery: { data, isLoading },
  } = useGetCourse(courseId);

  if (isLoading) {
    return <CourseDetailsSkeleton />;
  }

  if (!data) {
    return null;
  }

  const { title, progress, chapters } = data.data;

  const courseProgress = `${progress.completedModules}/${progress.totalModules} ${t("ui.modules")}`;
  return (
    <div className='flex flex-col bg-neutral-gray px-12 py-10'>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-row items-center gap-2'>
          <BooksIcon />
          <p className='text-gray-600 underline'>{t(`courses.${title}`)}</p>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <h1 className='font-tobias text-3xl'>{t(`courses.${title}`)}</h1>
          <p className='text-gray-600'>{courseProgress}</p>
        </div>
        {chapters.map((chapter) => (
          <ChapterCard key={chapter.id} chapter={chapter} />
        ))}
      </div>
    </div>
  );
};
