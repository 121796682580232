import type { SVGProps } from "~/constants";

export const RightValuesIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='18'
    height='121'
    viewBox='0 0 18 121'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M1 1L17 0.999999L17 44L1 44'
      stroke='#6C798B'
      strokeWidth='0.8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='4 4'
    />
    <path
      d='M1 49L17 49L17 120L1 120'
      stroke='#6C798B'
      strokeWidth='0.8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='4 4'
    />
  </svg>
);
