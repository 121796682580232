import React from "react";
import { useTranslation } from "react-i18next";

import type { SliderType } from "~/api/getSliderActivity";
import { Brain, ChemistryBottleSVG, Urine, UrineNA } from "~/assets";
import { Text } from "~/shared/components/ui";
import { Slider } from "~/shared/components/ui/Slider/Slider";

interface AmountSliderCardProps {
  value: number;
  setValue: (value: number) => void;
  slider: SliderType;
  position: number;
}

export const SLIDER_ICONS_BY_POSITION: Record<number, string> = {
  0: Brain,
  1: ChemistryBottleSVG,
  2: Urine,
  3: UrineNA,
};

export const AmountSliderCard = ({
  slider,
  value,
  setValue,
  position,
}: AmountSliderCardProps) => {
  const { t } = useTranslation();
  return (
    <div
      key={slider.id}
      className='relative flex w-full flex-row items-center justify-start gap-4 overflow-hidden rounded-xl border border-primary-30 bg-neutral-gray p-4'
    >
      <img src={SLIDER_ICONS_BY_POSITION[position]} alt='course' />

      <Slider
        min={Number(slider.minimum)}
        max={Number(slider.maximum)}
        value={value}
        onChange={(e) => setValue(Number(e.target.value))}
      />
      <Text className='min-w-[149px] text-xs text-gray-600'>
        {t(`activities.${slider.description}`)}
      </Text>
    </div>
  );
};
