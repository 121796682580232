import React from "react";

import Skeleton from "../ui/Skeleton/Skeleton";

export const CourseDetailsSkeleton = () => {
  return (
    <div className='flex animate-pulse flex-col bg-neutral-gray px-12 py-10'>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-row items-center gap-2'>
          <Skeleton className='size-6 rounded-full' />
          <Skeleton className='h-4 w-48 rounded' />
        </div>
        <div className='flex flex-row items-center justify-between'>
          <Skeleton className='h-8 w-80 rounded' />
          <Skeleton className='h-4 w-32 rounded' />
        </div>
        <div className='flex flex-col gap-4'>
          {Array.from({ length: 3 }).map((_, idx) => (
            <div key={idx} className='flex flex-col gap-4'>
              <div className='flex flex-col gap-6 rounded-xl bg-white p-6'>
                <div className='flex flex-row items-center justify-between'>
                  <div className='flex flex-row items-center gap-2'>
                    <Skeleton className='h-6 w-24 rounded' />
                    <Skeleton className='size-1 rounded-full' />
                    <Skeleton className='h-6 w-64 rounded' />
                  </div>
                  <div className='flex w-[40%] max-w-[25rem] flex-row items-center gap-6'>
                    <Skeleton className='h-4 w-full rounded-full' />
                    <Skeleton className='h-4 w-16 rounded' />
                  </div>
                </div>
                {Array.from({ length: 3 }).map((_, modIdx) => (
                  <Skeleton
                    key={modIdx}
                    className='h-24 w-full rounded-xl border border-gray-300 bg-gray-100'
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
