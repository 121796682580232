import type { SVGProps } from "~/constants";

export const BulletPoint = ({ className, ...props }: SVGProps) => (
  <svg
    width='6'
    height='12'
    viewBox='0 0 6 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 12C3.31371 12 6 9.31371 6 6C6 2.68629 3.31371 2.34843e-07 -1.04907e-06 5.24537e-07L0 12Z'
      fill='currentColor'
    />
  </svg>
);
