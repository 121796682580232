import { resolveValue, ToastBar, Toaster } from "react-hot-toast";

import { ErrorToast } from "./ErrorToast";
import { SuccessToast } from "./SuccessToast";

const CustomToaster = () => {
  return (
    <Toaster position='top-right'>
      {(t) => (
        <ToastBar
          style={{
            padding: 0,
            minWidth: "fit-content",
          }}
          toast={t}
        >
          {({ message }) =>
            t.type === "success" ? (
              <SuccessToast message={resolveValue(message, t)} />
            ) : (
              <ErrorToast message={resolveValue(message, t)} />
            )
          }
        </ToastBar>
      )}
    </Toaster>
  );
};

export default CustomToaster;
