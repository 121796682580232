export const CheckIcon = () => (
  <svg
    width='38'
    height='38'
    viewBox='0 0 38 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.5 19C2.5 9.8873 9.8873 2.5 19 2.5C28.1127 2.5 35.5 9.8873 35.5 19C35.5 28.1127 28.1127 35.5 19 35.5C9.8873 35.5 2.5 28.1127 2.5 19Z'
      fill='#B5DACD'
    />
    <path
      d='M2.5 19C2.5 9.8873 9.8873 2.5 19 2.5C28.1127 2.5 35.5 9.8873 35.5 19C35.5 28.1127 28.1127 35.5 19 35.5C9.8873 35.5 2.5 28.1127 2.5 19Z'
      stroke='#DAEDE6'
      strokeWidth='5'
    />
    <path
      d='M25.6667 14L16.5 23.1667L12.3334 19'
      stroke='#4B927E'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
