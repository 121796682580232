import type { SVGProps } from "~/constants";

export const D5WDragIcon = ({ className, ...props }: SVGProps) => (
  <svg
    className={className}
    {...props}
    viewBox='0 0 103 151'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='15' width='103' height='124' rx='11.3363' fill='#BFDAFE' />
    <rect
      x='3.99219'
      y='18.8018'
      width='95.0781'
      height='116'
      rx='8'
      fill='#EFF6FF'
    />
    <path
      d='M95 123.632V71.0634C95 70.8908 94.8878 70.7405 94.7202 70.6991C86.8165 68.7497 72.0285 70.8482 54.2532 77.3582C38.3673 83.1762 18.346 83.9379 8.4918 81.0165C8.24473 80.9433 8 81.1284 8 81.3861V123.632C8 127.701 11.2986 131 15.3677 131H87.6323C91.7014 131 95 127.701 95 123.632Z'
      fill='#BFDAFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54 143H50V149.822C50 150.472 50.5276 151 51.1784 151H52.8216C53.4724 151 54 150.472 54 149.822V143Z'
      fill='#BFDAFE'
    />
    <path
      d='M43.9062 139H59.9062C59.9062 141.209 58.1154 143 55.9062 143H47.9062C45.6971 143 43.9062 141.209 43.9062 139Z'
      fill='#BFDAFE'
    />
    <circle cx='51.5' cy='8.5' r='6.5' stroke='#BFDAFE' strokeWidth='4' />
    <path
      d='M59.4297 100.586L59.43 100.586C60.8991 102.054 61.8999 103.925 62.3056 105.962C62.7113 107.999 62.5037 110.111 61.7092 112.03C60.9147 113.949 59.5689 115.589 57.842 116.743C56.1152 117.897 54.0848 118.513 52.0078 118.513C49.9308 118.513 47.9005 117.897 46.1736 116.743C44.4468 115.589 43.1009 113.949 42.3064 112.03C41.5119 110.111 41.3044 107.999 41.7101 105.962C42.1158 103.925 43.1165 102.054 44.5856 100.586L44.5869 100.585L52.0013 93.1573L59.4297 100.586Z'
      stroke='#EFF6FF'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8163 50H9.64861V41.43H12.8122C13.6853 41.43 14.4372 41.6015 15.0677 41.9447C15.7009 42.285 16.1891 42.776 16.5323 43.4176C16.8754 44.0565 17.047 44.8209 17.047 45.7108C17.047 46.6035 16.8754 47.3707 16.5323 48.0123C16.1919 48.654 15.7051 49.1463 15.0718 49.4895C14.4386 49.8298 13.6867 50 12.8163 50ZM11.72 48.2341H12.7368C13.2167 48.2341 13.6226 48.1532 13.9546 47.9914C14.2893 47.8268 14.5418 47.5604 14.712 47.1921C14.8849 46.8211 14.9714 46.3273 14.9714 45.7108C14.9714 45.0943 14.8849 44.6033 14.712 44.2378C14.539 43.8696 14.2837 43.6045 13.9462 43.4427C13.6114 43.2781 13.1985 43.1959 12.7075 43.1959H11.72V48.2341ZM21.5203 50.1172C20.8954 50.1172 20.3402 50.0042 19.8548 49.7782C19.3694 49.5522 18.9858 49.2412 18.7041 48.8451C18.4251 48.4489 18.28 47.9942 18.2689 47.4809H20.2775C20.2942 47.7961 20.4225 48.05 20.6625 48.2425C20.9024 48.4322 21.1883 48.527 21.5203 48.527C21.7797 48.527 22.0099 48.4698 22.2108 48.3555C22.4116 48.2411 22.5692 48.0807 22.6836 47.8742C22.798 47.665 22.8538 47.4251 22.851 47.1545C22.8538 46.8783 22.7966 46.637 22.6794 46.4305C22.5651 46.2241 22.406 46.0637 22.2024 45.9493C22.0015 45.8321 21.77 45.7736 21.5077 45.7736C21.2595 45.7708 21.0237 45.8252 20.8006 45.9368C20.5802 46.0483 20.4128 46.2004 20.2984 46.3929L18.4697 46.0497L18.8422 41.43H24.3407V43.0996H20.5453L20.3486 45.1208H20.3988C20.5411 44.8836 20.7699 44.6884 21.0851 44.5349C21.4031 44.3787 21.7644 44.3006 22.1689 44.3006C22.6822 44.3006 23.1397 44.4205 23.5415 44.6605C23.946 44.8976 24.264 45.2268 24.4955 45.648C24.7299 46.0693 24.8471 46.5519 24.8471 47.0959C24.8471 47.6845 24.7076 48.2062 24.4286 48.6609C24.1524 49.1157 23.7646 49.4727 23.2653 49.7322C22.7687 49.9888 22.187 50.1172 21.5203 50.1172ZM28.0556 50L25.5616 41.43H27.8505L29.1436 47.0457H29.2147L30.6919 41.43H32.5582L34.0354 47.0582H34.1065L35.4037 41.43H37.6885L35.1987 50H33.2026L31.6585 44.8027H31.5916L30.0475 50H28.0556Z'
      fill='#257AEB'
    />
    <path
      d='M84 30.3483C83.0878 30.3483 82.3483 31.0878 82.3483 32C82.3483 32.9122 83.0878 33.6517 84 33.6517V30.3483ZM99.7993 30.3483H84V33.6517H99.7993V30.3483Z'
      fill='#BFDAFE'
    />
    <path
      d='M84 48.3483C83.0878 48.3483 82.3483 49.0878 82.3483 50C82.3483 50.9122 83.0878 51.6517 84 51.6517V48.3483ZM99.7993 48.3483H84V51.6517H99.7993V48.3483Z'
      fill='#BFDAFE'
    />
    <path
      d='M76 39.3483C75.0878 39.3483 74.3483 40.0878 74.3483 41C74.3483 41.9122 75.0878 42.6517 76 42.6517L76 39.3483ZM99 39.3483L76 39.3483L76 42.6517L99 42.6517L99 39.3483Z'
      fill='#BFDAFE'
    />
    <path
      opacity='0.32'
      d='M94.9032 123.892L95 72C91.8894 95.8851 70.7809 117.192 54.7715 127.598C53.3244 128.538 53.9852 131 55.7111 131H87.7953C91.7209 131 94.9032 127.818 94.9032 123.892Z'
      fill='#93C0FD'
    />
    <rect
      x='78'
      y='117.051'
      width='13.0249'
      height='9.89773'
      rx='4.94886'
      fill='#DBEAFE'
    />
    <path
      d='M80.0852 124.949L80.0852 123.938L82.2074 122.08C82.3665 121.936 82.5019 121.804 82.6136 121.685C82.7254 121.563 82.8106 121.442 82.8693 121.321C82.928 121.198 82.9574 121.064 82.9574 120.92C82.9574 120.759 82.9223 120.622 82.8523 120.509C82.7822 120.393 82.6856 120.304 82.5625 120.241C82.4394 120.179 82.2983 120.148 82.1392 120.148C81.9782 120.148 81.8371 120.181 81.7159 120.247C81.5947 120.312 81.5 120.405 81.4318 120.528C81.3655 120.652 81.3324 120.801 81.3324 120.977H80C80 120.581 80.089 120.24 80.267 119.952C80.4451 119.664 80.6951 119.442 81.017 119.287C81.3409 119.13 81.7169 119.051 82.1449 119.051C82.5862 119.051 82.9697 119.125 83.2955 119.273C83.6212 119.42 83.8731 119.627 84.0511 119.892C84.2311 120.155 84.321 120.461 84.321 120.81C84.321 121.031 84.2765 121.251 84.1875 121.469C84.0985 121.687 83.9384 121.927 83.7074 122.19C83.4782 122.454 83.1525 122.769 82.7301 123.136L82.0341 123.778V123.815L84.392 123.815V124.949H80.0852Z'
      fill='#60A2FA'
    />
    <path
      d='M85.1982 124.949V119.131L86.6044 119.131V123.807H89.0249V124.949H85.1982Z'
      fill='#60A2FA'
    />
  </svg>
);
