import type { SVGProps } from "~/constants";

export const ABCKidneySmallLogoIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='40'
    height='26'
    viewBox='0 0 40 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M12.9632 14.0791C15.5399 14.993 17.0143 17.6714 16.29 20.2811C15.523 23.0448 12.5806 24.685 9.71806 23.9445C3.99292 22.4634 0.699002 16.4084 2.36089 10.4204C4.02277 4.43226 10.0111 0.778611 15.7363 2.25969C17.6447 2.75339 18.7772 4.64724 18.2658 6.48973C17.7545 8.33222 15.7929 9.42563 13.8845 8.93194C13.302 8.78124 12.7918 8.50009 12.3785 8.12977M12.9632 14.0791C12.8111 14.0251 12.6552 13.9773 12.4957 13.9361C11.7729 13.7491 11.045 13.7139 10.3484 13.8102M12.9632 14.0791C13.7737 13.8505 14.4058 13.303 14.7963 12.6211M14.5903 9.11448C15.4265 10.1354 15.4121 11.5455 14.7963 12.6211M27.5318 14.0789C24.9551 14.9929 23.4807 17.6713 24.205 20.281C24.972 23.0447 27.9144 24.6849 30.7769 23.9443C36.5021 22.4633 39.796 16.4083 38.1341 10.4202C36.4722 4.43213 30.4839 0.77847 24.7587 2.25955C22.8503 2.75324 21.7178 4.6471 22.2292 6.48959C22.7405 8.33208 24.7021 9.42549 26.6105 8.9318C27.193 8.7811 27.7032 8.49995 28.1165 8.12963M27.5318 14.0789C27.6839 14.025 27.8398 13.9772 27.9993 13.9359C28.7221 13.7489 29.45 13.7137 30.1466 13.8101M27.5318 14.0789C26.7214 13.8504 26.0892 13.303 25.6988 12.6211M25.9047 9.11434C25.0685 10.1353 25.0829 11.5455 25.6988 12.6211M25.6988 12.6211V12.6211C23.0655 12.6211 20.8146 14.5168 20.3667 17.1117L20.2475 17.8018L20.1284 17.1116C19.6804 14.5167 17.4295 12.6211 14.7963 12.6211V12.6211'
      stroke='#FF852F'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
