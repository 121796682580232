import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ACTIVITY_TYPES } from "~/types";
import { ChoiceForm } from "./Choice";
import { DragAndDropRenderer } from "./DragAndDropRenderer";
import { SliderRenderer } from "./SliderRenderer";
import { Video } from "./video";

export const ActivityRenderer = () => {
  const { t } = useTranslation();
  const { type, activityId } = useParams();
  const key = `${type}${activityId}`;
  switch (type) {
    case ACTIVITY_TYPES.choice:
      return <ChoiceForm key={key} />;
    case ACTIVITY_TYPES.dragAndDrop:
      return <DragAndDropRenderer key={key} />;
    case ACTIVITY_TYPES.video:
      return <Video key={key} />;
    case ACTIVITY_TYPES.slider:
      return <SliderRenderer key={key} />;
    case ACTIVITY_TYPES.text:
      return <h1>{t("text")}</h1>;
    default:
      return <h1>{t("invalidType")}</h1>;
  }
};
