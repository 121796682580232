import React, { useState } from "react";

import { ABCKidneyLogoIcon, tw } from "~/shared";
import { ABCKidneySmallLogoIcon } from "~/shared/components/icons/ABCKidneySmallLogoIcon";
import { HideSidebarIcon } from "~/shared/components/icons/HideSidebarIcon";
import { ShowSidebarIcon } from "~/shared/components/icons/ShowSidebarIcon";

interface SidebarHeaderProps {
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
}

export const SidebarHeader = ({
  isExpanded,
  setIsExpanded,
}: SidebarHeaderProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const onSidebarExpanded = () => {
    setIsExpanded(true);
    setIsHovered(false);
  };

  return (
    <div
      className={tw(
        "flex w-full flex-row items-center justify-center",
        isExpanded && "justify-between",
      )}
    >
      {!isExpanded && (
        <div className='h-10 w-10'>
          {!isHovered ? (
            <ABCKidneySmallLogoIcon
              className='h-10'
              onClick={() => setIsExpanded(false)}
              onMouseEnter={() => setIsHovered(true)}
            />
          ) : (
            <button
              onClick={onSidebarExpanded}
              className='flex h-10 w-10 items-center justify-center rounded-md bg-neutral-background'
              onMouseLeave={() => setIsHovered(false)}
            >
              <ShowSidebarIcon className='text-gray-500' />
            </button>
          )}
        </div>
      )}
      {isExpanded && (
        <div className='flex h-10 w-full flex-row items-center justify-between'>
          <ABCKidneyLogoIcon className='h-6' />
          <button onClick={() => setIsExpanded(false)}>
            <HideSidebarIcon className='text-gray-500' />
          </button>
        </div>
      )}
    </div>
  );
};
