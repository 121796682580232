const PUBLIC_ROUTES = {
  login: "/login",
  signUp: "/signup/:inviteCode",
  forgotPassword: "/forgotPassword",
  resetPassword: "/resetPassword/:token",
} as const;

const PRIVATE_ROUTES = {
  home: "/",
  courseDetails: "/course/:courseId",
  activity:
    "/course/:courseId/chapter/:chapterId/module/:moduleId/:type/:activityId",
} as const;

export const ROUTES = {
  ...PUBLIC_ROUTES,
  ...PRIVATE_ROUTES,
} as const;

export const MODAL_ROUTES = {
  successModal: "/successModal",
} as const;
