import type { SVGProps } from "~/constants";

export const ErrorIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='38'
    height='38'
    viewBox='0 0 38 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M2.5 19C2.5 9.8873 9.8873 2.5 19 2.5C28.1127 2.5 35.5 9.8873 35.5 19C35.5 28.1127 28.1127 35.5 19 35.5C9.8873 35.5 2.5 28.1127 2.5 19Z'
      fill='#FFC1C1'
    />
    <path
      d='M2.5 19C2.5 9.8873 9.8873 2.5 19 2.5C28.1127 2.5 35.5 9.8873 35.5 19C35.5 28.1127 28.1127 35.5 19 35.5C9.8873 35.5 2.5 28.1127 2.5 19Z'
      stroke='#FFDDDD'
      strokeWidth='5'
    />
    <path
      d='M24 14L14 24'
      stroke='#AF0505'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 14L24 24'
      stroke='#AF0505'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
