import React from "react";
import { useTranslation } from "react-i18next";

import ResetPasswordForm from "~/shared/components/authorization/ResetPasswordForm";
import { Heading } from "~/shared/components/ui/Heading/Heading";

export const ResetPassword = () => {
  const { t } = useTranslation();
  return (
    <div className='flex w-2/3 flex-col gap-12'>
      <Heading className='font-tobias'>
        {t("resetPassword.resetYourPassword")}
      </Heading>
      <ResetPasswordForm />
    </div>
  );
};
