import React from "react";

import Skeleton from "../../ui/Skeleton/Skeleton";

export const ChoiceFormSkeleton = () => {
  return (
    <div className='flex h-full animate-pulse flex-col justify-between gap-4 bg-neutral-gray p-8'>
      <div className='flex flex-col gap-4'>
        <Skeleton className='h-6 w-3/4 rounded' />
        <div className='mt-4 flex flex-col gap-4'>
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className='flex items-center gap-4 rounded-lg border p-4'
            >
              <Skeleton className='size-4 rounded-full' />
              <Skeleton className='h-4 w-full rounded' />
            </div>
          ))}
        </div>
      </div>
      <div className='mt-8 flex justify-between'>
        <Skeleton className='h-6 w-32 rounded' />
        <Skeleton className='h-6 w-32 rounded' />
      </div>
    </div>
  );
};
