import {
  DragAndDropIcon,
  MultipleChoiceIcon,
  SliderIcon,
  TextActivityIcon,
  VideoIcon,
} from "~/shared/components";
import { ACTIVITY_TYPES } from "~/types";
import { tw } from "../tw";

const activityIconMap = {
  [ACTIVITY_TYPES.choice]: {
    IconComponent: MultipleChoiceIcon,
    backgroundColor: "bg-[#FFEBF3]",
  },
  [ACTIVITY_TYPES.slider]: {
    IconComponent: SliderIcon,
    backgroundColor: "bg-[#D4F5FF]",
  },
  [ACTIVITY_TYPES.dragAndDrop]: {
    IconComponent: DragAndDropIcon,
    backgroundColor: "bg-[#F3EDFD]",
  },
  [ACTIVITY_TYPES.video]: {
    IconComponent: VideoIcon,
    backgroundColor: "bg-[#FFF8CC]",
  },
  [ACTIVITY_TYPES.text]: {
    IconComponent: TextActivityIcon,
    backgroundColor: "bg-[#DAEDE6]",
  },
};

type Size = "md" | "sm";
export type ActivityType = keyof typeof ACTIVITY_TYPES;
interface GetActivityIconProps {
  activityType: ActivityType;
  size: Size;
}

const sizeClasses: Record<Size, { wrapper: string; icon: string }> = {
  md: {
    wrapper: "size-10",
    icon: "size-5",
  },
  sm: {
    wrapper: "size-6",
    icon: "size-4",
  },
};

export const getActivityIcon = ({
  activityType,
  size,
}: GetActivityIconProps) => {
  const { IconComponent, backgroundColor } = activityIconMap[activityType];
  const { wrapper: wrapperSizeClass, icon: iconSizeClass } = sizeClasses[size];

  return (
    <div
      className={tw(
        `flex items-center justify-center rounded-full`,
        wrapperSizeClass,
        backgroundColor,
      )}
    >
      <IconComponent className={iconSizeClass} />
    </div>
  );
};
