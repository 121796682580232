import type { SVGProps } from "~/constants";

export const LeftValuesIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='18'
    height='265'
    viewBox='0 0 18 369'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M17 1L1 0.999999L1 120L17 120'
      stroke='#8A96A6'
      strokeWidth='0.8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='4 4'
    />
    <path
      d='M17 124L0.999999 124L0.999996 368L17 368'
      stroke='#8A96A6'
      strokeWidth='0.8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='4 4'
    />
  </svg>
);
