import type { SVGProps } from "~/constants";
import type { Directions } from "~/shared/utils";
import { DIRECTIONS, getArrowDirectionClass, tw } from "~/shared/utils";

export interface ArrowIconProps extends SVGProps {
  direction?: Directions;
}

export const ArrowIcon = ({
  className,
  direction = DIRECTIONS.right,
  ...props
}: ArrowIconProps) => (
  <svg
    className={tw("h-3 w-3", getArrowDirectionClass(direction), className)}
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7 15.0001L7 2.00009M1 7.00009L6.29289 1.7072C6.68342 1.31668 7.31658 1.31668 7.70711 1.7072L13 7.00009'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
