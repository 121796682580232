import React from "react";
import { Trans } from "react-i18next";
import { useOutlet } from "react-router-dom";

import {
  ABCKidneyLogoIcon,
  LeftKidneyIcon,
  RightKidneyIcon,
  SophieAvatarIcon,
} from "~/shared";
import { Heading } from "~/shared/components/ui/Heading";
import DeviceViewNotSupported from "~/shared/components/utils/DeviceViewNotSupported";

const AuthenticationLayout = () => {
  const outlet = useOutlet();

  return (
    <>
      <div className='hidden h-dvh w-full bg-neutral-background md:flex'>
        <div className='relative z-10 w-1/2 bg-primary-50'>
          <div className='absolute -z-10 size-full'>
            <RightKidneyIcon className='absolute right-0 max-h-full max-w-[50%]' />
            <LeftKidneyIcon className='absolute bottom-0 left-0 max-h-full max-w-[50%]' />
          </div>
          <div className='flex h-full flex-col items-center justify-center gap-4'>
            <div className='flex flex-col items-center justify-center gap-4 text-center'>
              <ABCKidneyLogoIcon className='px-auto max-h-full max-w-[80%]' />
              <div>
                <Heading variant='h2'>
                  <Trans i18nKey={"authLayout.transformYourExperience"}>
                    transform your <br />
                    <p className='font-tobias'> learning experience.</p>
                  </Trans>
                </Heading>
              </div>
            </div>
            <div className='absolute bottom-10 flex flex-col items-center justify-center gap-4 text-center'>
              <p>
                <Trans i18nKey={"authLayout.createdBySophia"}>
                  created by
                  <b className='font-tobias font-semibold'>
                    Sophia Ambruso, DO
                  </b>
                </Trans>
              </p>
              <SophieAvatarIcon />
            </div>
          </div>
        </div>

        <div className='flex w-1/2 items-center justify-center'>{outlet}</div>
      </div>
      <DeviceViewNotSupported />
    </>
  );
};

export default AuthenticationLayout;
