import { useQuery } from "@tanstack/react-query";

import { getModuleProgress } from "~/api/courses/activities";

export const useGetModuleProgress = (id?: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getModuleProgress(id ?? ""),
    queryKey: ["getModuleProgress", id],
    enabled: !!id,
  });

  return { data, isLoading };
};
