import type { ModuleProgressData } from "~/types";
import type { ChoiceActivity, VideoActivity } from "~/types/activities";
import type { ServiceResponse } from "../axios";
import { api } from "../axios";

interface UpdateProgressParams {
  activityId: string;
  activityType: string;
}

export const getVideoActivity = async (id: string) => {
  const res = await api.get<ServiceResponse<VideoActivity>>(
    `/activities/video/${id}`,
  );
  return res.data.data;
};

export const getChoiceActivity = async (id: string) => {
  const res = await api.get<ServiceResponse<ChoiceActivity>>(
    `/activities/choice/${id}`,
  );
  return res.data.data;
};

export const getModuleProgress = async (id: string) => {
  const res = await api.get<ServiceResponse<ModuleProgressData>>(
    `/modules/${id}/progress`,
  );
  return res.data.data;
};

export const answerActivity = async (data: UpdateProgressParams) => {
  const res = await api.post<ServiceResponse<null>>(`/activities/answer`, {
    ...data,
  });
  return res.data.data;
};
