export const VasoconstrictIcon = () => (
  <svg
    width='15'
    height='16'
    viewBox='0 0 15 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.4987 14.6666C11.1806 14.6666 14.1654 11.6819 14.1654 7.99998C14.1654 4.31808 11.1806 1.33331 7.4987 1.33331C3.8168 1.33331 0.832031 4.31808 0.832031 7.99998C0.832031 11.6819 3.8168 14.6666 7.4987 14.6666Z'
      stroke='#3B8BF6'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.4987 10.6666C8.97146 10.6666 10.1654 9.47274 10.1654 7.99998C10.1654 6.52722 8.97146 5.33331 7.4987 5.33331C6.02594 5.33331 4.83203 6.52722 4.83203 7.99998C4.83203 9.47274 6.02594 10.6666 7.4987 10.6666Z'
      stroke='#3B8BF6'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
