import type { Activity, Chapter, Course, Module } from "~/types";

export const getPreviousActivity = (
  course?: Course,
  currentChapterId?: string,
  currentModuleId?: string,
  currentActivityId?: string,
  currentActivityType?: string,
) => {
  if (!course) return null;
  const currentChapterIndex = course.chapters.findIndex(
    (ch: Chapter) => ch.id == currentChapterId,
  );
  const currentChapter = course.chapters[currentChapterIndex];

  if (!currentChapter) return null;

  const currentModuleIndex = currentChapter.modules.findIndex(
    (mod: Module) => mod.id == currentModuleId,
  );
  const currentModule = currentChapter.modules[currentModuleIndex];

  if (!currentModule) return null;

  const currentActivityIndex = currentModule.activities.findIndex(
    (act: Activity) =>
      act.type === currentActivityType && act.id == currentActivityId,
  );

  // Case 1: There's a previous activity in the same module
  if (currentActivityIndex > 0) {
    const previousActivity = currentModule.activities[currentActivityIndex - 1];
    return {
      chapterId: currentChapter.id,
      moduleId: currentModule.id,
      activityId: previousActivity.id,
      activityType: previousActivity.type,
    };
  }

  // Case 2: No previous activities in this module, move to the last activity of the previous module
  if (currentModuleIndex > 0) {
    const previousModule = currentChapter.modules[currentModuleIndex - 1];
    const lastActivity =
      previousModule.activities[previousModule.activities.length - 1];
    return {
      chapterId: currentChapter.id,
      moduleId: previousModule.id,
      activityId: lastActivity.id,
      activityType: lastActivity.type,
    };
  }

  // Case 3: No previous modules in this chapter, move to the last module/activity of the previous chapter
  if (currentChapterIndex > 0) {
    const previousChapter = course.chapters[currentChapterIndex - 1];
    const lastModule =
      previousChapter.modules[previousChapter.modules.length - 1];
    const lastActivity =
      lastModule.activities[lastModule.activities.length - 1];
    return {
      chapterId: previousChapter.id,
      moduleId: lastModule.id,
      activityId: lastActivity.id,
      activityType: lastActivity.type,
    };
  }

  // Case 4: No previous chapters, already at the start of the course
  return {
    chapterId: null,
    moduleId: null,
    activityId: null,
    activityType: null,
  };
};
