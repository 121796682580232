import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAnswerActivity } from "~/shared/hooks/queries/activities/useAnswerActivity";
import { useActivitiesNavigation } from "~/shared/hooks/useActivitiesNavigation";
import { tw } from "~/shared/utils";
import { Button } from "../../ui";

interface NextAndPreviousButtonsProps {
  onCheckClick?: () => void;
  isCheckDisabled?: boolean;
  resetActivityState?: () => void;
}

export const NextAndPreviousButtons = ({
  onCheckClick,
  isCheckDisabled,
  resetActivityState,
}: NextAndPreviousButtonsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { type: activityType, activityId } = useParams();

  const { answerActivityMutation } = useAnswerActivity();

  const { nextPath, previousPath, nextLabel, previousLabel } =
    useActivitiesNavigation();

  if (!activityId || !activityType) return null;

  const onNext = () => {
    navigate(nextPath);
    answerActivityMutation({ activityId, activityType });
    if (resetActivityState) {
      resetActivityState();
    }
  };

  return (
    <div
      className={tw(
        "flex w-full flex-row justify-between",
        !previousLabel && "justify-end",
      )}
    >
      {previousLabel && (
        <Button
          onClick={() => navigate(previousPath)}
          variant='underline'
          type='button'
          className='justify-start pl-0'
        >
          {t(`activitiesNavigation.${previousLabel}`)}
        </Button>
      )}
      <div className='flex gap-4'>
        {onCheckClick && (
          <Button disabled={isCheckDisabled} onClick={onCheckClick}>
            Check answers
          </Button>
        )}
        <Button
          variant={onCheckClick ? "outline" : "default"}
          className='w-52'
          type='submit'
          onClick={onNext}
        >
          {t(`activitiesNavigation.${nextLabel}`)}
        </Button>
      </div>
    </div>
  );
};
